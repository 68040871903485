import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

interface Props {
  alt: string | null;
  className?: string;
}

export default function DefaultRecordingImage({ alt, className = undefined }: Props) {
  return (
    <StaticImage
      src="./images/default-recording-thumb.png"
      alt={alt || ''}
      placeholder="dominantColor"
      className={className || ''}
    />
  );
}
