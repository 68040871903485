import React from 'react';
import { MembershipPopupType } from '../components/MembershipPopup/MembershipPopup';

const MembershipPopupContext = React.createContext<{
  isMembershipModalOpen: boolean;
  showMembershipModal: (type?: MembershipPopupType) => void;
}>({
  isMembershipModalOpen: false,
  showMembershipModal: () => {
    throw new Error(
      'showMembershipModal was called outside of the MembershipPopupContext.Provider',
    );
  },
});

export default MembershipPopupContext;
