import * as React from 'react';
import { IndicatorSeparatorProps, components } from 'react-select';
import { Option } from './types';

export default function IndicatorSeparator<T>(props: IndicatorSeparatorProps<Option<T>>) {
  const { hasValue } = props;

  return hasValue ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.IndicatorSeparator {...props} />
  ) : null;
}
