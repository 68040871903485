import React from 'react';
import { AudioPlayerInstance } from '../../types';

const AudioPlayerContext = React.createContext<AudioPlayerInstance>({
  startPlayback: () => {},
  loadPlayback: () => {},
  audioRef: null,
  state: 'idle',
  playlist: null,
  currentItem: null,
  hasNext: false,
  hasPrev: false,
  currentTime: 0,
  isShuffling: false,
  isLooping: false,
  play: () => {},
  pause: () => {},
  reset: () => {},
  seekTo: () => {},
  playPrevious: () => {},
  playNext: () => {},
  toggleIsShuffling: () => {},
  toggleIsLooping: () => {},
});

export default AudioPlayerContext;
