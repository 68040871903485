import * as React from 'react';
import { StandaloneAudioPlayerType } from '../../types';

const StandaloneAudioPlayerContext = React.createContext<StandaloneAudioPlayerType>({
  isStandaloneAudioPlayerPlaying: false,
  setIsStandaloneAudioPlayerPlaying: (isStandaloneAudioPlayer) => isStandaloneAudioPlayer,
  currentlyPlayingItem: '',
  setCurrentlyPlayingItem: (currentlyPlayingItemId) => currentlyPlayingItemId,
});

export default StandaloneAudioPlayerContext;
