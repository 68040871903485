import * as React from 'react';
import styled from 'styled-components';
import EmbedTrackContext from '../../contexts/EmbedTrackContext';
import { embedTrackThemes } from './embed-track';

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
`;

const Label = styled.label<{ $theme: string }>`
  display: flex;
  input {
    width: 2px;
    height: 2px;
    opacity: 0;

    &:checked + span {
      border-color: var(--earth-fm--color--blue);
    }
  }

  span:not(.screen-reader-text) {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid transparent;
    border-radius: 50%;
    background-color: ${({ $theme }) =>
      $theme === 'green' ? 'var(--earth-fm--color--green)' : 'var(--earth-fm--color--yellow)'};
    transition: border-color 0.2s;
    cursor: pointer;

    &:hover,
    &:focus {
      border-color: var(--earth-fm--color--blue);
    }
  }
`;

export default function ThemeSelector() {
  const { theme, setTheme } = React.useContext(EmbedTrackContext);

  const handleThemeChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newTheme = e.target.value;
      setTheme(newTheme);
    },
    [setTheme],
  );

  return (
    <Wrapper>
      <p>Theme: </p>
      {embedTrackThemes.map((trackTheme) => (
        <Label key={trackTheme} htmlFor={`theme-${trackTheme}`} $theme={trackTheme}>
          <span className="screen-reader-text">{trackTheme}</span>
          <input
            id={`theme-${trackTheme}`}
            type="radio"
            name="theme"
            value={trackTheme}
            checked={trackTheme === theme}
            onChange={handleThemeChange}
          />
          <span />
        </Label>
      ))}
    </Wrapper>
  );
}
