import * as React from 'react';
import styled from 'styled-components';
import SliderControl from '../SliderControl';
import AudioPlayerContext from './AudioPlayerContext';
import VolumeIcon from './icons/volume.inline.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  @media (hover: none) {
    display: none;
  }

  svg {
    flex-shrink: 0;
    opacity: 0.8;
    transition: opacity 0.2s ease-in;
  }

  &:hover {
    svg {
      opacity: 1;
    }
  }
`;

const VolumeSlider = styled(SliderControl)`
  --track-height: 4px;
  --thumb-size: 10px;
  --thumb-opacity: 0;
  position: relative;
  margin-left: 12.5px;

  input {
    &:hover,
    &:focus {
      --thumb-opacity: 1;
    }
  }
`;

interface Props {
  className?: string;
}

export default function VolumeControl({ className = undefined }: Props) {
  const [volume, setVolume] = React.useState(1);
  const { audioRef } = React.useContext(AudioPlayerContext);

  const handleChange = React.useCallback(
    (newValue: number) => {
      setVolume(newValue);

      if (audioRef?.current) {
        audioRef.current.volume = newValue;
      }
    },
    [audioRef],
  );

  return (
    <Wrapper className={className}>
      <VolumeIcon />
      <VolumeSlider
        inputId="VolumeSlider"
        value={volume}
        min={0}
        max={1}
        step={0.01}
        onChange={handleChange}
      />
    </Wrapper>
  );
}
