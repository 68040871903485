import * as React from 'react';
import { OptionProps, components } from 'react-select';
import styled from 'styled-components';
import { Option } from './types';

const Checkbox = styled.div<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 10px;
  background: ${({ isChecked }) =>
    isChecked ? 'var(--earth-fm--color--green)' : 'rgba(255, 255, 255, 0.2)'};
  border: 1px solid rgba(2, 90, 77, 0.5);
  border-radius: 3px;
`;

export default function OptionComponent<T>(props: OptionProps<Option<T>>) {
  const { isSelected, label, isMulti } = props;

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.Option {...props}>
      {isMulti ? (
        <Checkbox isChecked={isSelected}>
          {isSelected ? (
            <svg width="12" height="9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M1.5 4.621l3.121 3.122L10.864 1.5"
                stroke="#fff"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : null}
        </Checkbox>
      ) : null}
      {label}
    </components.Option>
  );
}
