import * as React from 'react';
import styled, { StyledComponentProps, css } from 'styled-components';
import Spinner from './Spinner';

const Content = styled.span``;

const StyledSpinner = styled(Spinner)``;

const PrimaryButton = styled.button<{
  $danger: boolean;
  $small: boolean;
  $loading: boolean;
  $invert: boolean;
}>`
  all: unset;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  padding: ${(props) => (props.$small ? '2px 21px' : '11px 32px')};
  box-sizing: border-box;
  color: ${(props) => (props.$danger ? '#fff' : 'var(--earth-fm--color--green)')};
  background: ${(props) =>
    props.$danger ? 'var(--earth-fm--color--red)' : 'var(--earth-fm--color--yellow)'};
  font: inherit;
  font-size: ${(props) => (props.$small ? '14px' : '18px')};
  line-height: ${(props) => (props.$small ? 1.5 : 1.7)};
  font-weight: ${(props) => (props.$small ? 300 : 600)};
  border: 1px solid
    ${(props) => (props.$danger ? 'var(--earth-fm--color--red)' : 'var(--earth-fm--color--yellow)')};
  border-radius: ${(props) => (props.$small ? '5px' : '10px')};
  text-align: center;
  text-decoration: none;
  transition: background 0.2s ease;
  transition-property: color, background, border;
  cursor: pointer;
  overflow: hidden;
  isolation: isolate; /* fixes overflow hidden issue with border-radius on Safari */

  ${StyledSpinner} {
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: ${(props) => (props.$loading ? 1 : 0)};
    transform: translate(-50%, -50%);
    pointer-events: none;
  }

  ${Content} {
    opacity: ${(props) => (props.$loading ? 0 : 1)};
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    color: ${(props) => (props.$danger ? '#fff' : 'var(--earth-fm--color--green)')};
    border-color: ${(props) =>
      props.$danger ? 'var(--earth-fm--color--red)' : 'var(--earth-fm--color--green)'};
    text-decoration: none;
  }

  &:disabled {
    color: ${(props) => (props.$danger ? '#fff' : 'var(--earth-fm--color--dark-yellow)')};
    opacity: ${(props) => (props.$danger ? '0.15' : '1')};
    cursor: default;
  }

  ${(props) =>
    props.$invert
      ? css`
          border-width: 2px;
          transition:
            background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
            color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
            border-color 0.2s cubic-bezier(0.4, 0, 0.2, 1);

          &:hover:not(:disabled),
          &:focus:not(:disabled) {
            color: ${props.$danger ? 'var(--earth-fm--color--green)' : '#fff'};
            background-color: ${props.$danger ? '#fff' : 'var(--earth-fm--color--green)'};
            border-color: ${props.$danger
              ? 'var(--earth-fm--color--red)'
              : 'var(--earth-fm--color--yellow)'};

            ${StyledSpinner} {
              color: var(--earth-fm--color--beige);
            }
          }
        `
      : ''}
`;

const SecondaryButton = styled(PrimaryButton)`
  color: ${(props) =>
    props.$danger ? 'var(--earth-fm--color--red)' : 'var(--earth-fm--color--green)'};
  background: ${(props) => {
    if (props.$danger) {
      return props.$loading ? 'var(--earth-fm--color--red)' : 'none';
    }

    return props.$loading ? 'var(--earth-fm--color--green)' : 'none';
  }};
  border-color: ${(props) =>
    props.$danger ? 'var(--earth-fm--color--red)' : 'var(--earth-fm--color--green)'};

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    color: #fff;
    background: ${(props) =>
      props.$danger ? 'var(--earth-fm--color--red)' : 'var(--earth-fm--color--green)'};

    ${StyledSpinner} {
      color: #fff;
    }
  }

  &:disabled {
    color: ${(props) =>
      props.$danger ? 'var(--earth-fm--color--red)' : 'var(--earth-fm--color--green)'};
    opacity: 0.15;
  }
`;

type StyledComponentPropsWithAs<
  C extends string | React.ComponentType<any>,
  T extends object,
  O extends object,
  A extends keyof any,
  AsC extends string | React.ComponentType<any> = C,
  FAsC extends string | React.ComponentType<any> = C,
> = StyledComponentProps<C, T, O, A, FAsC> & {
  as?: AsC | undefined;
  forwardedAs?: FAsC | undefined;
};

interface Props {
  secondary?: boolean;
  danger?: boolean;
  loading?: boolean;
  small?: boolean;
  invert?: boolean;
}

export default function Button<C extends string | React.ComponentType<any>>({
  secondary = false,
  danger = false,
  loading = false,
  small = false,
  invert = false,
  children,
  ...rest
}: StyledComponentPropsWithAs<C, any, {}, never> & Props) {
  if (secondary) {
    return (
      <SecondaryButton
        $danger={danger}
        $small={small}
        $loading={loading}
        $invert={invert}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
      >
        <Content>{children}</Content>
        <StyledSpinner size={small ? 16 : 31} theme="dark" />
      </SecondaryButton>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <PrimaryButton
      $danger={danger}
      $small={small}
      $loading={loading}
      $invert={invert}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
    >
      <Content>{children}</Content>
      <StyledSpinner size={small ? 16 : 31} theme={danger ? 'dark' : 'light'} />
    </PrimaryButton>
  );
}
