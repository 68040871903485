const cache: {
  [key: string]: any;
} = {};

export default async function fetchWithCache<T>(url: string): Promise<T | null> {
  if (cache[url]) {
    return cache[url];
  }

  const res = await fetch(url);

  if (res.ok) {
    const data = await res.json();
    // put data in cache
    cache[url] = data;
    return data;
  }

  return null;
}
