import { Link } from 'gatsby';
import * as React from 'react';
import { Portal } from 'react-portal';
import styled from 'styled-components';
import * as focusTrap from 'focus-trap';
import { cssClampValue } from '../utils/common';
import Button from './Button';
import Spinner from './Spinner';
import zIndexes from '../z-indexes';
import useKeyboardEventHandler from '../hooks/useKeyboardEventHandler';

export type ModalTheme = 'green' | 'white';

let instanceCounter = 1;
function getUniqueTitleId() {
  const id = `earth-fm-modal-title-${instanceCounter}`;
  instanceCounter += 1;
  return id;
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  pointer-events: none;
  z-index: ${zIndexes.modal};

  @media (min-width: 768px) {
    justify-content: center;
  }
`;

const Container = styled.div<{ $small: boolean; $theme: ModalTheme }>`
  pointer-events: auto;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 60px);
  background: ${(props) => (props.$theme === 'green' ? 'var(--earth-fm--color--green)' : '#fff')};
  color: ${(props) => (props.$theme === 'green' ? '#fff' : 'var(--earth-fm--color--text)')};

  @media (min-width: 768px) {
    position: relative;
    max-width: ${(props) => (props.$small ? '380px' : '620px')};
    margin: 0 auto;
    border-radius: 20px;
  }
`;

const Header = styled.div<{ $hideBorder: boolean }>`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 25px 30px;
  border-bottom: ${(props) =>
    props.$hideBorder ? 'none' : '1px solid var(--earth-fm--color--green-15)'};
`;

const Title = styled.h2`
  margin: 0;
  word-wrap: break-word;
  word-break: break-word;
  overflow-wrap: break-word;
  flex: 1 1;
  align-self: center;
  font-family: var(--earth-fm--font-family--jakarta);
  font-size: 20px;
  line-height: 150%;
  font-weight: 700;
`;

const CloseButton = styled.button`
  all: unset;
  box-sizing: border-box;
  cursor: pointer;

  svg {
    display: block;
  }
`;

const BodyWrapper = styled.div<{ $titleHidden: boolean }>`
  padding: ${(props) => (props.$titleHidden ? '0 30px 30px' : '20px 30px 30px')};
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;

  *:first-child {
    margin-top: 0;
  }

  *:last-child {
    margin-bottom: 0;
  }
`;

const SpinnerWrapper = styled.div`
  text-align: center;
`;

const Footer = styled.div<{ $hideBorder: boolean }>`
  display: flex;
  width: 100%;
  padding: 15px 30px;
  align-self: flex-end;
  align-items: center;
  justify-content: flex-end;
  gap: ${cssClampValue(20, 30)};
  border-top: ${(props) =>
    props.$hideBorder ? 'none' : '1px solid var(--earth-fm--color--green-15)'};
`;

const Backdrop = styled.div<{ $theme: ModalTheme; $open: boolean }>`
  position: fixed;
  z-index: ${zIndexes.modalBackdrop};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  background-color: ${(props) =>
    props.$theme === 'green' ? 'var(--earth-fm--color--beige)' : 'var(--earth-fm--color--green)'};
  opacity: ${(props) => (props.$open ? 0.8 : 0)};
  transition: opacity 0.2s;
  backface-visibility: hidden;

  body:has(&) #___gatsby {
    filter: ${(props) => (props.$open ? 'blur(5px)' : 'none')};
  }
`;

interface ModalButton {
  content: string;
  onClick?: () => void;
  url?: string;
  target?: string;
  loading?: boolean;
  disabled?: boolean;
  danger?: boolean;
}

interface Props {
  open: boolean;
  title: React.ReactNode;
  titleHidden?: boolean;
  children?: React.ReactNode;
  /** Replaces modal content with a spinner while a background action is being performed. */
  loading?: boolean;
  small?: boolean;
  onClose?: () => void;
  primaryButton?: ModalButton;
  secondaryButtons?: ModalButton[];
  hideHeaderSeparator?: boolean;
  hideFooterSeparator?: boolean;
  className?: string;
  theme?: ModalTheme;
}

export default function Modal({
  open,
  title,
  titleHidden = false,
  children = null,
  loading = false,
  small = false,
  onClose = undefined,
  primaryButton = undefined,
  secondaryButtons = undefined,
  hideHeaderSeparator = false,
  hideFooterSeparator = false,
  className = undefined,
  theme = 'white',
}: Props) {
  const titleId = React.useMemo(() => getUniqueTitleId(), []);
  const dialogRef = React.useRef<HTMLDivElement>(null);
  const modalContainerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (open && dialogRef.current) {
      const firstFocusableElement = modalContainerRef.current!;

      // create a focus trap
      const trap = focusTrap.createFocusTrap(firstFocusableElement, {
        fallbackFocus: dialogRef.current,
        onActivate: () => firstFocusableElement.classList.add('is-active'),
        onDeactivate: () => firstFocusableElement.classList.remove('is-active'),
      });

      // activate trap when first focusable element is focused
      firstFocusableElement.addEventListener('focus', () => trap.activate());

      // deactivate trap when modal is closed
      return () => {
        trap.deactivate();
        firstFocusableElement.removeEventListener('focus', () => trap.activate());
      };
    }
    return () => {};
  }, [open]);

  React.useEffect(() => {
    if (open && dialogRef.current) {
      const dialogWrapper = dialogRef.current.children[0] as HTMLDivElement;
      dialogWrapper.setAttribute('tabIndex', '0');
      dialogWrapper.style.outline = 'none';
      dialogWrapper.focus();
    }
  }, [open]);

  const handleKeyUp = useKeyboardEventHandler('Escape', onClose || (() => {}));

  if (!open) {
    return null;
  }

  return (
    <Portal>
      <Wrapper
        role="dialog"
        aria-modal="true"
        aria-labelledby={titleId}
        tabIndex={-1}
        ref={dialogRef}
        onKeyUp={handleKeyUp}
        className={className}
      >
        <Container $small={small} $theme={theme} ref={modalContainerRef}>
          <Header $hideBorder={hideHeaderSeparator}>
            <Title id={titleId}>
              {titleHidden ? <span className="screen-reader-text">{title}</span> : title}
            </Title>
            {onClose && (
              <CloseButton onClick={onClose}>
                <div className="screen-reader-text">Close</div>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 22 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  role="img"
                >
                  <path
                    d="M1 1l10 10m0 0l10 10M11 11L21 1M11 11L1 21"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </CloseButton>
            )}
          </Header>
          <BodyWrapper $titleHidden={titleHidden}>
            {loading ? (
              <SpinnerWrapper>
                <Spinner />
              </SpinnerWrapper>
            ) : (
              children
            )}
          </BodyWrapper>
          {primaryButton || secondaryButtons ? (
            <Footer $hideBorder={hideFooterSeparator}>
              {secondaryButtons?.map((secondaryButton) => {
                if (secondaryButton.onClick) {
                  return (
                    <Button
                      key={secondaryButton.content}
                      secondary
                      onClick={secondaryButton.onClick}
                      loading={secondaryButton.loading}
                      disabled={secondaryButton.disabled}
                      danger={secondaryButton.danger}
                    >
                      {secondaryButton.content}
                    </Button>
                  );
                }
                if (secondaryButton.url) {
                  return (
                    <Button
                      key={secondaryButton.url}
                      secondary
                      as={Link}
                      to={secondaryButton.url}
                      target={secondaryButton.target}
                      loading={secondaryButton.loading}
                      danger={secondaryButton.danger}
                    >
                      {secondaryButton.content}
                    </Button>
                  );
                }

                return null;
              })}

              {primaryButton && (primaryButton.url || primaryButton.onClick) ? (
                <Button
                  key="primary-button"
                  as={primaryButton.url ? Link : 'button'}
                  to={primaryButton.url}
                  target={primaryButton.target}
                  onClick={primaryButton.onClick}
                  loading={primaryButton.loading}
                  disabled={primaryButton.disabled}
                  danger={primaryButton.danger}
                >
                  {primaryButton?.content}
                </Button>
              ) : null}
            </Footer>
          ) : null}
        </Container>
      </Wrapper>
      <Backdrop $theme={theme} $open={open} onClick={onClose} />
    </Portal>
  );
}
