import { useDebounceCallback } from '@react-hook/debounce';
import * as React from 'react';
import {
  AudioPlayerInstance,
  AudioPlayerState,
  Membership,
  PlaybackItem,
  Playlist,
  StandaloneAudioPlayerType,
} from '../../types';
import { arraySample, getTotalPlayedTime } from '../../utils/common';
import { logPlayerEvent, safeAudioPlay } from '../../utils/frontend';
import StandaloneAudioPlayerContext from './StandaloneAudioPlayerContext';
import MembershipPopupContext from '../../contexts/MembershipPopupContext';
import { loggedInNonMemberTimeLimit, nonLoggedInUserTimeLimit } from '../../../config';
import FirebaseContext from '../../contexts/FirebaseContext';

const playerDataStorageKey = 'earth.fm:audio-player:data';
const playerWasShownStorageKey = 'earth.fm:audio-player:was-shown';

interface Options {
  syncInLocalStorage?: boolean;
  defaultPlaybackItem?: PlaybackItem | null;
}

export default function useAudioPlayer({
  syncInLocalStorage = true,
  defaultPlaybackItem = null,
}: Options = {}): AudioPlayerInstance {
  const [state, setState] = React.useState<AudioPlayerState>('idle');
  const [playlist, setPlaylist] = React.useState<Playlist | null>(null);
  const [currentItem, setCurrentItem] = React.useState<PlaybackItem | null>(null);
  const [currentTime, setCurrentTime] = React.useState(0);
  const [isShuffling, setIsShuffling] = React.useState(false);
  const [isLooping, setIsLooping] = React.useState(false);
  const { currentlyPlayingItem, setCurrentlyPlayingItem, isStandaloneAudioPlayerPlaying } =
    React.useContext<StandaloneAudioPlayerType>(StandaloneAudioPlayerContext);

  const { user, membership } = React.useContext(FirebaseContext);

  const { showMembershipModal } = React.useContext(MembershipPopupContext);
  // Using ref to so that it doesn't trigger auto play when used play callback
  const showMembershipRef = React.useRef(showMembershipModal);
  React.useEffect(() => {
    showMembershipRef.current = showMembershipModal;
  }, [showMembershipModal]);
  const freeTimeLimitReachedRef = React.useRef(false);

  // reset freeTimeLimitReachedRef when user becomes a member
  React.useEffect(() => {
    if (membership !== Membership.None) {
      freeTimeLimitReachedRef.current = false;
    }
  }, [membership]);

  const audioRef = React.useRef<HTMLAudioElement>();
  const autoPlayOnLoad = React.useRef(true);
  const initialAudioPosition = React.useRef(0);
  const playStartEventLogged = React.useRef(false);
  const playEventSource = React.useRef<string | null>(null);

  const totalPlayedTimeRef = React.useRef(getTotalPlayedTime().totalPlayed);
  // Make sure we call getTotalPlayedTime only once every 5 seconds for performance reasons
  const checkFreeTimeLimit = React.useMemo(() => Math.round(currentTime) % 5 === 0, [currentTime]);
  React.useEffect(() => {
    totalPlayedTimeRef.current = getTotalPlayedTime().totalPlayed;
    const timeLimit = user ? loggedInNonMemberTimeLimit : nonLoggedInUserTimeLimit;
    if (
      totalPlayedTimeRef.current > timeLimit &&
      membership === Membership.None &&
      audioRef.current &&
      !audioRef.current.paused
    ) {
      freeTimeLimitReachedRef.current = true;
      showMembershipRef.current('free-limit-reached');
      audioRef.current?.pause();
    } else {
      freeTimeLimitReachedRef.current = false;
    }
  }, [checkFreeTimeLimit, membership, user, state]);

  const loadLastPlaybackItem = React.useCallback(() => {
    if (!syncInLocalStorage) {
      return false;
    }

    if (typeof window === 'undefined') {
      return false;
    }

    if (currentItem) {
      return false; // return if current item is already set
    }

    const dataStr = localStorage.getItem(playerDataStorageKey);
    if (dataStr) {
      try {
        const data: {
          playlist?: Playlist | null;
          currentItem?: PlaybackItem | null;
          currentTime?: number;
          isShuffling?: boolean;
          isLooping?: boolean;
        } = JSON.parse(dataStr);

        if (!data.currentItem) {
          return false; // return if currentItem is missing
        }

        if (
          data.playlist &&
          !data.playlist.items.find((item) => item.id === data.currentItem?.id)
        ) {
          return false; // return if playlist exists but current item is no longer part of it
        }

        if (data.currentItem) {
          if (audioRef.current) {
            return false; // return if player is already active
          }
          if (data.currentTime && !data.currentItem.isLivestream) {
            initialAudioPosition.current = data.currentTime;
            setCurrentTime(data.currentTime);
          }
          autoPlayOnLoad.current = false;
          setCurrentItem(data.currentItem);
          // only bottom player supports loading from local storage
          playEventSource.current = 'bottom_player';
        }
        if (data.playlist) {
          setPlaylist(data.playlist);
        }
        if (data.isShuffling) {
          setIsShuffling(data.isShuffling);
        }
        if (data.isLooping) {
          setIsLooping(data.isLooping);
        }

        return true;
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    }

    // make sure saved player data load only once par page load
    localStorage.removeItem(playerDataStorageKey);
    return false;
  }, [currentItem, syncInLocalStorage]);

  // load player data from local storage or load the default playback item if no data is found in local storage
  React.useEffect(() => {
    const loadedFromLocalStorage = loadLastPlaybackItem();
    const playerWasShown = localStorage.getItem(playerWasShownStorageKey) === 'true';
    // load the default playback item if the user is new and has not started a playback yet
    if (!loadedFromLocalStorage && !audioRef.current && defaultPlaybackItem && !playerWasShown) {
      autoPlayOnLoad.current = false;
      setCurrentItem(defaultPlaybackItem);
      // only bottom player adds default playback item on page load
      playEventSource.current = 'bottom_player';
    }
  }, [currentItem, defaultPlaybackItem, loadLastPlaybackItem, syncInLocalStorage]);

  // save player data in local storage on page exit
  React.useEffect(() => {
    if (!syncInLocalStorage) {
      return () => {};
    }

    if (typeof window === 'undefined') {
      return () => {};
    }

    function savePlayerData() {
      try {
        localStorage.setItem(
          playerDataStorageKey,
          JSON.stringify({ playlist, currentItem, currentTime, isShuffling, isLooping }),
        );
      } catch (error) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.error(error);
        }
      }
    }

    // Save player data whenever the page is backgrounded or unloaded.
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        savePlayerData();
      }
    };
    window.addEventListener('visibilitychange', handleVisibilityChange);

    // NOTE: Safari does not reliably fire the `visibilitychange` event when the
    // page is being unloaded.
    window.addEventListener('pagehide', savePlayerData);

    return () => {
      window.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('pagehide', savePlayerData);
    };
  }, [currentItem, currentTime, isLooping, isShuffling, syncInLocalStorage, playlist]);

  const startPlayback: AudioPlayerInstance['startPlayback'] = React.useCallback((params) => {
    if (params.playlist && !params.playlist.items.find((item) => item.id === params.item.id)) {
      throw new Error('Item not in playlist!');
    }

    setCurrentItem(params.item);
    setPlaylist(params.playlist || null);
    playEventSource.current = params.eventSource || null;
  }, []);

  const loadPlayback: AudioPlayerInstance['loadPlayback'] = React.useCallback((params) => {
    setCurrentItem(params.item);
    autoPlayOnLoad.current = false;
    playEventSource.current = params.eventSource || null;
  }, []);

  const hasNext = React.useMemo(() => {
    if (!playlist || playlist.items.length < 2 || !currentItem) {
      return false;
    }

    const currentItemIndex = playlist.items.findIndex((item) => item.id === currentItem.id);
    return currentItemIndex < playlist.items.length - 1;
  }, [currentItem, playlist]);

  const hasPrev = React.useMemo(() => {
    if (!playlist || playlist.items.length < 2 || !currentItem) {
      return false;
    }

    const currentItemIndex = playlist.items.findIndex((item) => item.id === currentItem.id);
    return currentItemIndex > 0;
  }, [currentItem, playlist]);

  const playPrevious = React.useCallback(() => {
    if (!playlist || playlist.items.length === 0 || !currentItem) {
      return;
    }

    const currentItemIndex = playlist.items.findIndex((item) => item.id === currentItem.id);
    const prevItemIndex = currentItemIndex > 0 ? currentItemIndex - 1 : playlist.items.length - 1;

    setCurrentItem(playlist.items[prevItemIndex]);
    playEventSource.current = 'playlist_prev';
  }, [currentItem, playlist]);

  const playNext = React.useCallback(() => {
    if (!playlist || playlist.items.length === 0 || !currentItem) {
      return;
    }

    const currentItemIndex = playlist.items.findIndex((item) => item.id === currentItem.id);
    const nextItemIndex = currentItemIndex < playlist.items.length - 1 ? currentItemIndex + 1 : 0;

    setCurrentItem(playlist.items[nextItemIndex]);
    playEventSource.current = 'playlist_next';
  }, [currentItem, playlist]);

  const playNextRandom = React.useCallback(() => {
    if (!playlist || playlist.items.length === 0) {
      return;
    }

    // if there is only one item in the list then just restart it
    if (playlist.items.length === 1) {
      if (audioRef.current) {
        audioRef.current.currentTime = 0;
        safeAudioPlay(audioRef.current.play());
      }
      return;
    }

    // else randomly find next item which is different the current one
    let nextItem = arraySample(playlist.items);
    while (nextItem.id === currentItem?.id) {
      nextItem = arraySample(playlist.items);
    }

    setCurrentItem(nextItem);
    playEventSource.current = 'playlist_next_random';
  }, [currentItem?.id, playlist]);

  const play = React.useCallback(() => {
    safeAudioPlay(audioRef.current?.play());

    if (freeTimeLimitReachedRef.current) {
      showMembershipRef.current('free-limit-reached');
      audioRef.current?.pause();
    }

    if (audioRef.current) {
      setCurrentlyPlayingItem(audioRef.current?.src);
    }

    // log recording_play_start event every time a recording starts playing for the first time
    // (i.e. when the player loads with a initial position > 0 on page load and user clicks play)
    if (playEventSource.current && currentItem && playStartEventLogged.current === false) {
      logPlayerEvent('recording_play_start', currentItem.id, playEventSource.current, {
        startTime: Math.round(currentTime),
      });
      playStartEventLogged.current = true;
    }
  }, [currentItem, currentTime, setCurrentlyPlayingItem]);

  // reset playStartEventLogged when currentItem changes
  React.useEffect(() => {
    playStartEventLogged.current = false;
  }, [currentItem]);

  // if a standalone audio player is playing other audio players pause
  React.useEffect(() => {
    const isCurrentAudioPlayerPlaying =
      audioRef.current && currentlyPlayingItem === audioRef.current.src;

    if (!isCurrentAudioPlayerPlaying) {
      audioRef.current?.pause();
    }
  }, [setCurrentlyPlayingItem, currentlyPlayingItem]);

  // if standalone audio player unmount audio player pause;
  React.useEffect(() => {
    if (isStandaloneAudioPlayerPlaying) {
      return () => {
        audioRef.current?.pause();
      };
    }
    return () => {};
  }, [isStandaloneAudioPlayerPlaying]);

  const pause = React.useCallback(() => {
    try {
      audioRef.current?.pause();
    } catch (error) {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
  }, []);

  const reset = React.useCallback(() => {
    setState('idle');
    setCurrentItem(null);
    playEventSource.current = null;
    setPlaylist(null);
    setCurrentTime(0);
  }, []);

  const seekTo = React.useCallback((time: number) => {
    if (!audioRef.current || typeof time !== 'number') {
      return;
    }
    audioRef.current.currentTime = time;
  }, []);

  const toggleIsShuffling = React.useCallback(() => {
    setIsShuffling((shuffling) => !shuffling);
  }, []);

  const toggleIsLooping = React.useCallback(() => {
    setIsLooping((looping) => !looping);
  }, []);

  React.useEffect(() => {
    if (!currentItem?.sourceUrl) {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = undefined;
      }
      return undefined;
    }

    if (!audioRef.current) {
      audioRef.current = new Audio(currentItem.sourceUrl);
    }

    const audio = audioRef.current;

    if (audio.src !== currentItem.sourceUrl) {
      audio.src = currentItem.sourceUrl;
    }

    const handleLoading = () => {
      setState('buffering');
    };

    const handleWaiting = () => {
      setState('buffering');
    };

    const handlePlaying = () => {
      setState('playing');
    };

    const handlePause = () => {
      setState('paused');
    };

    const handleTimeupdate = () => {
      setCurrentTime(audioRef.current?.currentTime || 0);
    };

    const handleError = () => {
      setState('error');
    };

    const handleEnded = () => {
      if (playlist) {
        const isLastItem =
          playlist.items.length > 1
            ? playlist.items.findIndex(({ id }) => currentItem.id === id) ===
              playlist.items.length - 1
            : true;

        if (!isLastItem || isLooping) {
          if (isShuffling) {
            playNextRandom();
          } else {
            playNext();
          }
        }
      } else if (isLooping && audioRef.current) {
        audioRef.current.currentTime = 0;
        safeAudioPlay(audioRef.current.play());
      } else {
        setState('idle');
      }
      showMembershipRef.current();
    };

    audio.addEventListener('loading', handleLoading);
    audio.addEventListener('waiting', handleWaiting);
    audio.addEventListener('playing', handlePlaying);
    audio.addEventListener('pause', handlePause);
    audio.addEventListener('timeupdate', handleTimeupdate);
    audio.addEventListener('error', handleError);
    audio.addEventListener('ended', handleEnded);

    if (autoPlayOnLoad.current) {
      safeAudioPlay(audio.play());

      if (freeTimeLimitReachedRef.current) {
        showMembershipRef.current('free-limit-reached');
        audioRef.current?.pause();
      }

      if (playEventSource.current && currentItem && playStartEventLogged.current === false) {
        logPlayerEvent('recording_play_start', currentItem.id, playEventSource.current, {
          startTime: Math.round(initialAudioPosition.current),
        });
        playStartEventLogged.current = true;
      }
    }

    if (initialAudioPosition.current > 0) {
      audio.currentTime = initialAudioPosition.current;
    }

    initialAudioPosition.current = 0; // reset initialAudioPosition after first use
    autoPlayOnLoad.current = true; // reset autoPlayOnLoad after first use

    return () => {
      audio.removeEventListener('loading', handleLoading);
      audio.removeEventListener('waiting', handleWaiting);
      audio.removeEventListener('playing', handlePlaying);
      audio.removeEventListener('pause', handlePause);
      audio.removeEventListener('timeupdate', handleTimeupdate);
      audio.removeEventListener('error', handleError);
      audio.removeEventListener('ended', handleEnded);
    };
  }, [currentItem, isLooping, isShuffling, playNext, playNextRandom, playlist]);

  React.useEffect(() => {
    if (typeof window !== 'undefined' && 'mediaSession' in window.navigator) {
      if (currentItem) {
        navigator.mediaSession.metadata = new MediaMetadata({
          title: currentItem.userFriendlyTitle || currentItem.title,
          artist: currentItem.recordists
            ? currentItem.recordists.map((recordist) => recordist.title).join(', ')
            : currentItem.recordist || '',
        });
      } else {
        navigator.mediaSession.metadata = null;
      }
    }
  }, [currentItem]);

  React.useEffect(() => {
    if (typeof window !== 'undefined' && currentItem) {
      localStorage.setItem(playerWasShownStorageKey, 'true');
    }
  }, [currentItem]);

  return {
    audioRef,
    state,
    playlist,
    currentItem,
    hasNext,
    hasPrev,
    currentTime,
    isShuffling,
    isLooping,
    startPlayback,
    loadPlayback,
    play,
    pause,
    reset,
    seekTo: useDebounceCallback(seekTo),
    playPrevious,
    playNext,
    toggleIsShuffling,
    toggleIsLooping,
  };
}
