import * as React from 'react';
import styled from 'styled-components';
import Select from '../Select';
import EmbedTrackContext from '../../contexts/EmbedTrackContext';
import { embedTrackSize } from './embed-track';

const StyledSelect = styled(Select)`
  &.embed-track-size {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 5px;
`;

export default function SizeSelector() {
  const { size, setSize } = React.useContext(EmbedTrackContext);
  const sizeOptions = React.useMemo(() => {
    const array = Object.values(embedTrackSize);
    return array;
  }, []);

  const handleSizeChange = React.useCallback(
    (newValue: number[]) => {
      setSize(sizeOptions.find((item) => item.value === newValue[0])?.value || 350);
    },
    [setSize, sizeOptions],
  );

  return (
    <Wrapper>
      <StyledSelect
        label="Size"
        className="embed-track-size"
        isClearable={false}
        selected={[size]}
        options={sizeOptions}
        onChange={(value) => handleSizeChange(value as number[])}
        disableMenuPortalTarget
      />
    </Wrapper>
  );
}
