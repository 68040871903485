import * as React from 'react';
import { DropdownIndicatorProps, components } from 'react-select';
import { Option } from './types';

export default function DropdownIndicator<T>(props: DropdownIndicatorProps<Option<T>>) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <components.DropdownIndicator {...props}>
      <svg width="24" height="13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.662 1.334l10.331 10.332L22.325 1.334"
          stroke="currentColor"
          strokeWidth="1.687"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </components.DropdownIndicator>
  );
}
