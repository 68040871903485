import { onAuthStateChanged, User } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { setUserId, setUserProperties } from 'firebase/analytics';
import * as React from 'react';
import { v5 as uuidv5 } from 'uuid';
import FirebaseContext from '../contexts/FirebaseContext';
import { auth, db, analytics } from '../firebase';
import logger from '../logger';
import { Favorites, MemberProfileData, Membership } from '../types';
import { favoritesRef, getMembership } from '../utils/frontend';

interface Props {
  children: React.ReactNode;
}

export default function FirebaseProvider({ children }: Props) {
  const [initializing, setInitializing] = React.useState(true);
  const [user, setUser] = React.useState<User | null>(null);
  const [loadingMembership, setLoadingMembership] = React.useState(true);
  const [membership, setMembership] = React.useState<Membership>(Membership.None);
  const [loadingMember, setLoadingMember] = React.useState(true);
  const [member, setMember] = React.useState<MemberProfileData | null>(null);
  const [loadingFavorites, setLoadingFavorites] = React.useState(true);
  const [favorites, setFavorites] = React.useState<Favorites>({
    recordings: [],
    playlists: [],
    podcasts: [],
  });

  React.useEffect(() => {
    onAuthStateChanged(
      auth,
      (newUser) => {
        setInitializing(false);
        setUser(newUser);
        setLoadingMembership(newUser !== null);
        setMembership(Membership.None);
        setMember(null);
        setLoadingMember(newUser !== null);
      },
      (error) => logger.error('Error listening to auth state changes', error),
    );
  }, []);

  React.useEffect(() => {
    if (!user) {
      return undefined;
    }

    getMembership(user).then((m) => {
      setMembership(m);
      setLoadingMembership(false);
    });

    const unsubscribe = onSnapshot(
      doc(db, 'members', user.uid, 'public', 'profile'),
      { includeMetadataChanges: true },
      (snap) => {
        if (snap.metadata.fromCache || snap.metadata.hasPendingWrites) {
          return;
        }
        if (snap.exists()) {
          setMember({
            ...snap.data(),
            id: user.uid,
          } as MemberProfileData);
        } else {
          setMember(null);
        }
        setLoadingMember(false);
      },
      (error) => logger.error('Error listening to member profile data changes', error),
    );

    return () => unsubscribe();
  }, [user]);

  React.useEffect(() => {
    if (!user) {
      return undefined;
    }

    const unsubscribe = onSnapshot(
      favoritesRef(user.uid),
      (snap) => {
        if (snap.exists()) {
          setFavorites(snap.data() as Favorites);
        } else {
          setFavorites({ recordings: [], playlists: [], podcasts: [] });
        }
        setLoadingFavorites(false);
      },
      (error) => logger.error('Error listening to member favorites data changes', error),
    );

    return () => unsubscribe();
  }, [user]);

  // set user properties for google analytics custom dimensions
  React.useEffect(() => {
    if (user && analytics) {
      const myNamespace = 'f1b0b7a0-4b9e-11eb-b378-0242ac130002';
      setUserId(analytics, uuidv5(user.uid, myNamespace));
      setUserProperties(analytics, {
        membership: membership === Membership.None ? 'guest' : membership,
      });
    }
  }, [user, membership]);

  const contextValue = React.useMemo(
    () => ({
      initializing,
      user,
      loadingMembership,
      membership,
      loadingMember,
      member,
      loadingFavorites,
      favorites,
    }),
    [
      initializing,
      user,
      loadingMembership,
      membership,
      loadingMember,
      member,
      loadingFavorites,
      favorites,
    ],
  );

  return <FirebaseContext.Provider value={contextValue}>{children}</FirebaseContext.Provider>;
}
