const logger = {
  error: (context: string, err: unknown) => {
    if (process.env.NODE_ENV !== 'production') {
      // eslint-disable-next-line no-console
      console.error(context);
      // eslint-disable-next-line no-console
      console.error(err);
    }
    // TODO: maybe integrate with an error tracking service
  },
};

export default logger;
