export const embedTrackSize = {
  standard: {
    label: 'Standard (350px)',
    value: 350,
  },
  compact: {
    label: 'Compact (190px)',
    value: 190,
  },
};

export const embedTrackThemes = ['green', 'yellow'];
