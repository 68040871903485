import * as React from 'react';
import styled, { keyframes } from 'styled-components';

type SpinnerTheme = 'light' | 'dark';

const spin = keyframes`
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.span<{ size: number; theme: SpinnerTheme }>`
  display: inline-block;
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  color: ${(props) =>
    props.theme === 'light' ? 'var(--earth-fm--color--green)' : 'var(--earth-fm--color--beige)'};

  svg {
    animation: ${spin} 1s linear infinite;
  }
`;

interface Props {
  size?: number;
  theme?: SpinnerTheme;
  className?: string;
}

export default function Spinner({ size = 31, theme = 'light', className = undefined }: Props) {
  return (
    <Wrapper size={size} theme={theme} className={className}>
      <svg viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle
          cx="16.5"
          cy="16.5"
          r="14.5"
          stroke="currentColor"
          strokeOpacity=".15"
          strokeWidth="4"
        />
        <path
          d="M2 16.5C2 24.508 8.492 31 16.5 31S31 24.508 31 16.5 24.508 2 16.5 2"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
        />
      </svg>
    </Wrapper>
  );
}
