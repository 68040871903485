import * as React from 'react';
import ReactSelect, { MultiValue, SingleValue, StylesConfig } from 'react-select';
import styled from 'styled-components';
import { Option } from './types';
import OptionComponent from './OptionComponent';
import IndicatorSeparator from './IndicatorSeparator';
import DropdownIndicator from './DropdownIndicator';
import zIndexes from '../../z-indexes';

const Label = styled.label``;

const LabelText = styled.span`
  display: block;
  margin-bottom: 18px;
`;

interface Props<T> {
  label: string;
  placeholder?: string;
  className?: string;
  selected: T[];
  options: Option<T>[];
  isMulti?: boolean;
  isClearable?: boolean;
  labelHidden?: boolean;
  noOptionsMessage?: ((obj: { inputValue: string }) => string) | undefined;
  onChange: (selected: T[]) => void;
  disableMenuPortalTarget?: boolean;
}

export default function Select<T = number | string>({
  label,
  placeholder = undefined,
  className = undefined,
  selected,
  options,
  isMulti = false,
  isClearable = true,
  labelHidden = false,
  noOptionsMessage = undefined,
  onChange,
  disableMenuPortalTarget = undefined,
}: Props<T>) {
  const value = React.useMemo(() => {
    if (isMulti) {
      return options.filter((option) => selected.includes(option.value));
    }
    return options.find((option) => selected.includes(option.value)) || [];
  }, [isMulti, options, selected]);

  const handleChange = React.useCallback(
    (selectedOptions: MultiValue<Option<T>> | SingleValue<Option<T>>) => {
      if (selectedOptions instanceof Array) {
        onChange(selectedOptions.map(({ value: val }) => val));
      } else if (selectedOptions?.value) {
        onChange([selectedOptions.value]);
      } else {
        onChange([]);
      }
    },
    [onChange],
  );

  const customStyles: StylesConfig<Option<T>> = React.useMemo(
    () => ({
      control: (provided) => ({
        ...provided,
        minHeight: '59px',
        minWidth: '140px',
        flexWrap: 'nowrap',
        background: 'none',
        border: '1px solid var(--earth-fm--color--green)',
        borderColor: 'var(--earth-fm--color--green)',
        boxShadow: 'none',
        transition: 'all 0.2s ease-out',
        ':hover': {
          borderColor: 'rgba(2, 90, 77, 0.15)',
        },
        ':has(:focus-visible)': {
          borderColor: 'var(--earth-fm--color--blue)',
          boxShadow: 'inset 0 0 0 1px var(--earth-fm--color--blue)',
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: '0 0 0 20px',
        overflow: 'unset',
      }),
      placeholder: (provided) => ({
        ...provided,
        color: 'var(--earth-fm--color--green)',
        whiteSpace: 'nowrap',
      }),
      input: (provided) => ({
        ...provided,
        color: 'var(--earth-fm--color--green)',
      }),
      singleValue: (provided) => ({
        ...provided,
        overflow: 'unset',
        color: 'var(--earth-fm--color--green)',
        paddingRight: '10px',
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        margin: '0 10px 0 0',
        padding: '10px',
        color: 'var(--earth-fm--color--green)',
        transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : undefined,

        ':hover': {
          color: 'var(--earth-fm--color--green)',
        },
      }),
      clearIndicator: (provided) => ({
        ...provided,
        color: '#fff',
        background: 'var(--earth-fm--color--green)',
        borderRadius: '50%',
        margin: '13px 10px 10px 0',
        padding: '0',
        cursor: 'pointer',

        ':hover': {
          color: '#fff',
        },

        '> *': {
          padding: '4px',
        },
      }),
      indicatorSeparator: (provided) => ({
        ...provided,
        background: 'transparent',
      }),
      menuPortal(base) {
        return {
          ...base,
          zIndex: zIndexes.modal,
        };
      },
      menu: (provided) => ({
        ...provided,
        border: '0',
        boxShadow: 'none',
      }),
      menuList: (provided) => ({
        ...provided,
        padding: '10px',
      }),
      option: (provided, state) => ({
        ...provided,
        display: 'flex',
        alignItems: 'center',
        padding: '10px 15px',
        fontSize: '18px',
        lineHeight: 1.7,
        fontWeight: state.isSelected ? 600 : 300,
        color: 'var(--earth-fm--color--green)',
        background: state.isSelected || state.isFocused ? 'var(--earth-fm--color--beige)' : 'none',

        ':not(:first-of-type)': {
          marginTop: '5px',
        },

        ':hover': {
          background: state.isSelected
            ? 'var(--earth-fm--color--beige)'
            : 'rgba(249, 230, 224, 0.39)',
        },
      }),
      noOptionsMessage: (provided) => ({
        ...provided,
        color: 'rgba(2, 90, 77, 0.50)',
      }),
    }),
    [],
  );

  return (
    <Label className={className}>
      <LabelText className={labelHidden ? 'screen-reader-text' : undefined}>{label}</LabelText>
      <ReactSelect
        placeholder={
          isMulti && selected.length > 0 && placeholder
            ? `${placeholder} (${selected.length})`
            : placeholder
        }
        className={className ? `${className.split(' ')[0]}__select` : undefined}
        value={value}
        options={options}
        isMulti={isMulti}
        noOptionsMessage={noOptionsMessage}
        styles={customStyles}
        theme={(theme) => ({
          ...theme,
          borderRadius: 10,
          colors: {
            ...theme.colors,
            primary: 'var(--earth-fm--color--green)',
          },
        })}
        isClearable={isClearable}
        isSearchable
        menuPortalTarget={
          typeof document !== 'undefined' && !disableMenuPortalTarget ? document.body : null
        }
        closeMenuOnSelect={!isMulti}
        hideSelectedOptions={false}
        controlShouldRenderValue={!isMulti}
        components={{
          Option: OptionComponent<T>,
          IndicatorSeparator,
          DropdownIndicator,
        }}
        onChange={handleChange}
      />
    </Label>
  );
}
