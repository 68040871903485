import * as React from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { pages, siteUrl } from '../../../config';
import { cssClampValue } from '../../utils/common';

const Wrapper = styled.div<{ $size: number; $isOpen: boolean }>`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: ${({ $size }) => `${$size}px`};
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  transition: opacity 500ms ease;

  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background: rgb(2, 90, 77);
    background: linear-gradient(90deg, rgba(2, 90, 77, 0.8) 0%, rgba(2, 90, 77, 1) 100%);
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  color: #fff;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;

  h3 {
    width: 90%;
    margin: 0 auto 1rem;
    font-size: ${cssClampValue(16, 18)};
  }

  @media (min-width: 1600px) {
    h3 {
      font-size: ${cssClampValue(16, 24)};
    }
  }
`;

const CloseButton = styled.button`
  --standard-padding: 30px;
  --compact-padding: 20px;
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;

  svg {
    display: block;
  }
`;

type Props = {
  trackSize: number;
  isPopupOpen: boolean;
  status: 'becomeAMember' | 'default';
  handleClose: () => void;
};

export default function BecomeAMemberPopup({ trackSize, isPopupOpen, status, handleClose }: Props) {
  return (
    <Wrapper $size={trackSize} $isOpen={isPopupOpen}>
      <Content>
        <CloseButton onClick={handleClose}>
          <div className="screen-reader-text">Close</div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            role="img"
          >
            <path
              d="M1 1l10 10m0 0l10 10M11 11L21 1M11 11L1 21"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </CloseButton>
        {status === 'default' ? (
          <h3>
            Listen to nature sounds,
            <br /> fall in love with the Earth 🌳🎧
          </h3>
        ) : (
          <h3>
            Listen to the full track & <br />
            900+ nature sounds on Earth.fm 🌳🎧
          </h3>
        )}
        {status === 'default' ? (
          <Button as="a" href={`${siteUrl}${pages.home}`} rel="noopener noreferrer" target="_blank">
            Visit Earth.fm
          </Button>
        ) : (
          <Button
            as="a"
            href={`${siteUrl}${pages.home}`}
            rel="noopener noreferrer"
            target="_blank"
            small
          >
            Become a member
          </Button>
        )}
      </Content>
    </Wrapper>
  );
}
