/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { cssClampValue } from '../utils/common';

export const PlainButton = styled.button`
  all: unset;
  box-sizing: border-box;
  color: var(--earth-fm--color--green);
  font: inherit;
  cursor: pointer;

  &:disabled {
    opacity: 0.15;
  }
`;

export const PageTitle = styled.h1`
  margin-top: 0;
  font-size: ${cssClampValue(29, 64)};
  line-height: 1.5;

  @media (min-width: 768px) {
    line-height: 1.25;
  }
`;

export const PageHeader = styled.div`
  max-width: 811px;

  ${PageTitle} {
    margin-bottom: 20px;
  }

  p {
    margin: 0;
  }

  p + p {
    margin-top: 16px;
  }
`;

export const PageTemplateLayout = styled.div`
  --earth-fm--post-content--max-width: 1088px;
  max-width: 1088px;
  margin: ${cssClampValue(0, 24)} auto 0;

  ${PageTitle} {
    margin: 0 0 ${cssClampValue(40, 60)};
  }
`;

export const ErrorMessage = styled.div`
  font-size: 14px;
  line-height: 150%;
  color: var(--earth-fm--color--red);
`;

export const LatestItemCount = styled.p`
  margin: 0 0 ${cssClampValue(10, 30)} 0;
  font-family: var(--earth-fm--font-family--jakarta);
  font-size: ${cssClampValue(14, 18)};
  line-height: 1.7;
  font-weight: 300;
  opacity: 0.5;

  @media (prefer-contrast: more) {
    opacity: 1;
  }
`;

export const LatestItemTitle = styled.h2`
  margin: 0;
  margin-bottom: ${cssClampValue(10, 36)};
  font-family: var(--earth-fm--font-family--jakarta);
  font-size: ${cssClampValue(18, 26)};

  &:has(+ ${LatestItemCount}) {
    margin-bottom: ${cssClampValue(4, 8)};
  }
`;
