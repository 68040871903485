import * as React from 'react';
import SearchContext from '../../contexts/SearchContext';

export default function SearchContextProvider({ children }: { children: React.ReactNode }) {
  const [query, setQuery] = React.useState('');
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const [isShowingFilters, setIsShowingFilters] = React.useState(false);
  const [isNavigatingWithKeyboard, setIsNavigatingWithKeyboard] = React.useState(false);

  const searchContextValue = React.useMemo(
    () => ({
      query,
      setQuery,
      isSearchOpen,
      setIsSearchOpen,
      isShowingFilters,
      setIsShowingFilters,
      isNavigatingWithKeyboard,
      setIsNavigatingWithKeyboard,
    }),
    [query, isSearchOpen, isShowingFilters, isNavigatingWithKeyboard],
  );

  return <SearchContext.Provider value={searchContextValue}>{children}</SearchContext.Provider>;
}
