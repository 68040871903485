/**
 * A modal component that prompts the user to become a member.
 */
import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { pages } from '../../../config';
import Modal from '../Modal';
import { cssClampValue } from '../../utils/common';
import Button from '../Button';
import FirebaseContext from '../../contexts/FirebaseContext';
import { Membership } from '../../types';

const ContentWrapper = styled.div`
  display: flex;
  gap: ${cssClampValue(24, 32)};

  p {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }

  @media (min-width: 768px) {
    p {
      font-size: 18px;
    }
  }
`;

const IllustrationWrapper = styled.div`
  flex-shrink: 0;
  width: ${cssClampValue(40, 60)};
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;
  margin-left: calc(
    ${cssClampValue(40, 60)} + ${cssClampValue(24, 32)}
  ); // illustration width + gap
`;

export type MembershipPopupType = 'default' | 'free-limit-reached';

interface Props {
  isOpen: boolean;
  type?: MembershipPopupType;
  onClose: () => void;
}

export default function MembershipPopup({ isOpen, type = 'default', onClose }: Props) {
  const { user, membership, loadingMembership } = React.useContext(FirebaseContext);

  const isLoggedInUser = user !== null;
  const isPaidMember = isLoggedInUser && !loadingMembership && membership !== Membership.None;

  // Don't show the popup if the user is already a member in any way
  if (isPaidMember) {
    return null;
  }

  return (
    <Modal
      open={isOpen}
      title="Become a member"
      titleHidden
      theme={type === 'free-limit-reached' ? 'green' : 'white'}
      hideHeaderSeparator
      hideFooterSeparator
      onClose={onClose}
    >
      <ContentWrapper>
        <IllustrationWrapper>
          <StaticImage
            placeholder="none"
            src="./images/crown.png"
            alt="Colorful illustration of a crown"
          />
        </IllustrationWrapper>
        {type === 'free-limit-reached' ? (
          <p>
            {isLoggedInUser
              ? 'You‘ve reached your free listening limit. Please upgrade to continue listening.'
              : 'You‘ve reached your free listening limit. Please become a member to continue listening.'}
          </p>
        ) : (
          <p>
            Dear friend, we&lsquo;re{' '}
            <a href={pages.about} onClick={onClose} target="_blank" rel="noopener noreferrer">
              100% self-funded & non-profit.
            </a>{' '}
            Please help Earth.fm continue to exist by becoming a member.
          </p>
        )}
      </ContentWrapper>
      <ButtonWrapper>
        <Button as="a" href={pages.becomeAMember} onClick={onClose}>
          {isLoggedInUser ? 'Upgrade' : 'Become a member'}
        </Button>
      </ButtonWrapper>
    </Modal>
  );
}
