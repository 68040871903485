const zIndexes = {
  actionMenu: 9999,
  header: 9999,
  headerSearchResults: 9998, // header - 1
  modal: 99999,
  modalBackdrop: 9999,
  audioPlayer: 999,
  audioPlayerFullscreen: 9999,
  mapRecordingPreview: 9999,
  toast: 999999,
};

export default zIndexes;
