import * as React from 'react';

export default function useKeyboardEventHandler(keyCode: string | string[], callback: () => void) {
  const handleKeyUp = React.useCallback(
    (event: React.KeyboardEvent) => {
      if (Array.isArray(keyCode)) {
        keyCode.forEach((code) => {
          if (event.code === code) {
            callback();
          }
        });
      }

      if (event.code === keyCode) {
        callback();
      }
    },
    [callback, keyCode],
  );

  return handleKeyUp;
}
