import * as React from 'react';
import styled, { css } from 'styled-components';
import { embedTrackSize } from '../EmbedTrackModal/embed-track';
import { pages, siteUrl } from '../../../config';
import logger from '../../logger';
import { addItemToLibrary } from '../../utils/frontend';
import { cssClampValue } from '../../utils/common';
import { PlainButton } from '../styled-components';

const standard = embedTrackSize.standard.value;

const MenuWrapper = styled.div<{ $menuOpen?: boolean; $theme: string }>`
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: ${cssClampValue(5, 10)};
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  transform: translateY(100%);
  color: ${({ $theme }) => ($theme === 'yellow' ? 'var(--earth-fm--color--green)' : '#fff')};
  background-color: ${({ $theme }) =>
    $theme === 'yellow' ? 'var(--earth-fm--color--yellow)' : 'var(--earth-fm--color--green)'};
  padding: 10px 20px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition:
    opacity 0.2s ease-out 0.2s,
    visibility 0.2s 0.2s,
    transform 0s 0.4s;

  ${({ $menuOpen }) =>
    $menuOpen
      ? css`
          transform: translateY(0);
          opacity: 1;
          visibility: visible;
        `
      : ''};
`;

const Menus = styled.ul<{ $menuOpen: boolean; $theme: string }>`
  display: flex;
  min-width: max(30%, 230px);
  max-width: 70%;
  padding: 0;
  margin: 0;
  margin-bottom: 10px;
  color: ${({ $theme }) => ($theme === 'yellow' ? '#fff' : 'var(--earth-fm--color--green)')};
  opacity: 0;
  list-style: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transform: translateY(-10px);
  transition:
    opacity 0.2s ease-out 0.4s,
    transform 0.2s ease-out 0.4s;

  ${({ $menuOpen }) =>
    $menuOpen &&
    css`
      opacity: 1;
      transform: translateY(0);
    `}
`;

const MenuButton = styled(PlainButton)``;

const MenuItem = styled.li<{ $size: number; $theme: string }>`
  display: flex;
  width: 100%;

  ${MenuButton} {
    display: flex;
    width: 100%;
    padding: ${({ $size }) => ($size === standard ? '10px 20px' : '3px 10px 3px 20px')};
    margin-bottom: 5px;
    border-radius: 5px;
    font-size: 14px;
    color: inherit;
    background-color: ${({ $theme }) =>
      $theme === 'yellow' ? 'var(--earth-fm--color--green)' : 'var(--earth-fm--color--yellow)'};
    border: ${({ $theme }) =>
      $theme === 'yellow'
        ? '1px solid var(--earth-fm--color--green)'
        : '1px solid var(--earth-fm--color--yellow)'};
    transition: border 0.2s ease-in-out;
    cursor: pointer;
    justify-content: left;
    align-items: center;

    &:hover {
      border-color: ${({ $theme }) =>
        $theme === 'yellow'
          ? '1px solid var(--earth-fm--color--yellow)'
          : '1px solid var(--earth-fm--color--green)'};
    }
  }
`;

const Privacy = styled.a<{ $size: number }>`
  display: block;
  font-size: 12px;
  text-align: center;
  color: inherit;
  position: absolute;
  bottom: ${({ $size }) =>
    $size === standard ? `calc(var(--standard-padding) / 2)` : 'calc(var(--compact-padding) / 2)'};
`;

const CloseButton = styled.button<{ $size: number }>`
  --standard-padding: 30px;
  --compact-padding: 20px;
  all: unset;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  top: ${({ $size }) =>
    $size === standard ? 'var(--standard-padding)' : 'var(--compact-padding)'};
  right: ${({ $size }) =>
    $size === standard ? 'var(--standard-padding)' : 'var(--compact-padding)'};

  svg {
    display: block;
  }
`;

type Props = {
  recordingId: number;
  recordingUri: string;
  trackTheme: string;
  trackSize: number;
  menuOpen: boolean;
  handleClose: () => void;
  isCopied: boolean;
  setIsCopied: React.Dispatch<React.SetStateAction<boolean>>;
  isMember: boolean;
  memberId: string | null;
};

export default function Menu({
  recordingId,
  recordingUri,
  trackTheme,
  trackSize,
  menuOpen,
  handleClose,
  isCopied,
  setIsCopied,
  isMember,
  memberId,
}: Props) {
  const menuItems = React.useMemo(() => {
    if (!recordingId) {
      return null;
    }

    return [
      {
        label: 'Listen on Earth.fm',
        onClick: () => {
          window.open(`${siteUrl}${recordingUri}`);
        },
        hasPopup: true,
      },
      {
        label: 'Add to library',
        onClick: () => {
          if (isMember && memberId) {
            addItemToLibrary('recordings', recordingId, memberId)
              .then(() => window.open(`${siteUrl}${pages.memberLibrary}`))
              .catch((error) => {
                logger.error('Error adding recording to library', error);
              });
          } else {
            window.open(`${siteUrl}${pages.becomeAMember}`);
          }
        },
        to: `${siteUrl}${pages.memberLibrary}`,
        target: '_blank',
        hasPopup: true,
      },
      {
        label: 'Copy link',
        onClick: () => {
          navigator.clipboard.writeText(`${siteUrl}${recordingUri}`).then(() => {
            setIsCopied(true);
          });
        },
        hasPopup: false,
      },
    ];
  }, [recordingId, isMember, memberId, recordingUri, setIsCopied]);

  React.useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false);
      }, 2000);

      return () => clearTimeout(timer);
    }

    return () => {};
  }, [setIsCopied, isCopied]);

  return (
    <MenuWrapper $menuOpen={menuOpen} $theme={trackTheme}>
      <CloseButton $size={trackSize} onClick={handleClose}>
        <div className="screen-reader-text">Close</div>
        <svg
          width="20"
          height="20"
          viewBox="0 0 22 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          role="img"
        >
          <path
            d="M1 1l10 10m0 0l10 10M11 11L21 1M11 11L1 21"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </CloseButton>
      <Menus $menuOpen={menuOpen} $theme={trackTheme}>
        {menuItems &&
          menuItems.map((item) => (
            <MenuItem
              key={`embed-menu-item-${item.label}`}
              onClick={item.onClick}
              aria-haspopup={item.hasPopup}
              $size={trackSize}
              $theme={trackTheme}
            >
              <MenuButton>
                {item.label === 'Copy link' && isCopied ? 'Link copied!' : item.label}
              </MenuButton>
            </MenuItem>
          ))}
      </Menus>
      <Privacy
        href={pages.externalPrivacyPolicyUrl}
        target="_blank"
        rel="noopener noreferrer"
        $size={trackSize}
      >
        Privacy & Policy
      </Privacy>
    </MenuWrapper>
  );
}
