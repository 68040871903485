import * as React from 'react';
import styled from 'styled-components';
import { ErrorMessage } from './styled-components';
import SearchContext from '../contexts/SearchContext';

const Wrapper = styled.div`
  label {
    display: block;
    margin: 0 0 10px 11px;
    font: inherit;
  }

  input,
  textarea {
    display: block;
    width: 100%;
    padding: 14px 20px;
    font: inherit;
    color: inherit;
    background: none;
    border: 1px solid var(--earth-fm--color--green);
    border-radius: 10px;
    outline: 0;
    box-shadow: none;
    transition: border 200ms ease-out;

    &[aria-invalid='true'] {
      color: var(--earth-fm--color--red);
      border-color: var(--earth-fm--color--red);
    }

    &:hover,
    &:focus {
      border-color: var(--earth-fm--color--border-lighter);
    }
  }

  ${ErrorMessage} {
    margin: 5px 0 0 11px;
  }
`;

const HelpText = styled.p`
  margin: 10px 0 0 20px;
  font-size: 14px;
  color: var(--earth-fm--color--green-50);

  @media (prefers-contrast: more) {
    color: var(--earth-fm--color--green);
  }
`;

type Type =
  | 'text'
  | 'email'
  | 'number'
  | 'password'
  | 'search'
  | 'tel'
  | 'url'
  | 'date'
  | 'datetime-local'
  | 'month'
  | 'time'
  | 'week'
  | 'currency';

interface Props {
  id: string;
  className?: string;
  label: React.ReactNode;
  labelHidden?: boolean;
  value?: string;
  type?: Type;
  placeholder?: string;
  helpText?: React.ReactNode;
  disabled?: boolean;
  readOnly?: boolean;
  autoFocus?: boolean;
  focused?: boolean;
  multiline?: boolean | number;
  error?: string | boolean;
  name?: string;
  role?: string;
  step?: number;
  autoComplete?: string;
  autoCorrect?: string;
  autoCapitalize?: string;
  max?: number | string;
  maxLength?: number;
  maxHeight?: number | string;
  min?: number | string;
  minLength?: number;
  pattern?: string;
  spellCheck?: boolean;
  required?: boolean;
  ariaOwns?: string;
  ariaExpanded?: boolean;
  ariaControls?: string;
  ariaActiveDescendant?: string;
  ariaAutocomplete?: string;
  /** Visual required indicator, adds an asterisk to label */
  requiredIndicator?: boolean;
  onChange?: (value: string, id: string) => void;
  onFocus?: (event?: React.FocusEvent) => void;
  onBlur?: (event?: React.FocusEvent) => void;
}

export default function TextField({
  id,
  label,
  labelHidden = false,
  helpText = null,
  type = 'text',
  focused = false,
  multiline = false,
  error = false,
  requiredIndicator = false,
  onChange = undefined,
  value = undefined,
  className = undefined,
  ...rest
}: Props) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);
  const {
    isShowingFilters,
    setIsNavigatingWithKeyboard,
    setQuery,
    setIsSearchOpen,
    setIsShowingFilters,
  } = React.useContext(SearchContext);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (onChange) {
        onChange(event.currentTarget.value, id);
      }
    },
    [id, onChange],
  );

  React.useEffect(() => {
    const input = multiline ? textAreaRef.current : inputRef.current;
    if (!input || focused === undefined) {
      return;
    }
    if (focused) {
      input.focus();
    } else {
      input.blur();
    }
  }, [focused, multiline]);

  const handleKeyDown = React.useCallback(
    (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const input = multiline ? textAreaRef.current : inputRef.current;

      if (event.key === 'ArrowDown' || event.key === 'Enter') {
        // if user presses enter or arrow down key within search input or filter is showing,
        // move focus to the first filter menu in search result.
        if ((input?.value && input.value.length > 0) || isShowingFilters) {
          setIsNavigatingWithKeyboard(true);
          const firstFilterMenu = document.querySelector<HTMLAnchorElement>('.ais-Menu-list li a');

          if (firstFilterMenu) {
            firstFilterMenu.focus();
          }
        }

        // if user presses enter or arrow down key but search input is empty,
        // move focus to the first item within default search result.
        if (input?.value === '') {
          event.preventDefault();
          setIsNavigatingWithKeyboard(true);
          const defaultContent: HTMLDivElement | null = document.querySelector(
            '.default-content-true > div button',
          );

          if (defaultContent) {
            defaultContent.focus();
          }
        }
      }

      // if user presses escape key on search text field,
      // close search result and reset search input.
      if (event.key === 'Escape') {
        setIsNavigatingWithKeyboard(false);
        setIsSearchOpen(false);
        setIsShowingFilters(false);
        setQuery('');
      }
    },
    [
      isShowingFilters,
      multiline,
      setIsNavigatingWithKeyboard,
      setIsSearchOpen,
      setIsShowingFilters,
      setQuery,
    ],
  );

  return (
    <Wrapper className={className}>
      <label className={labelHidden ? 'screen-reader-text' : undefined} htmlFor={id}>
        {label}
      </label>
      {multiline ? (
        <textarea
          id={id}
          ref={textAreaRef}
          rows={typeof multiline === 'number' ? multiline : 8}
          aria-required={requiredIndicator}
          aria-invalid={!!error}
          aria-label="Text field. Type keywords. To navigate within the search results, press enter or arrow down key and then press tab. Press escape key to clear the search results."
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={value}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
      ) : (
        <input
          id={id}
          ref={inputRef}
          type={type}
          aria-required={requiredIndicator}
          aria-invalid={!!error}
          aria-label="Search input. Type keywords. To navigate within the search results, press enter or arrow down key and then press tab. Press escape key to clear the search results."
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={value}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
        />
      )}
      {typeof error === 'string' ? <ErrorMessage>{error}</ErrorMessage> : null}
      {helpText ? <HelpText>{helpText}</HelpText> : null}
    </Wrapper>
  );
}
