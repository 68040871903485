import * as React from 'react';
import { Portal } from 'react-portal';
import styled from 'styled-components';
import { ToastProps, ToastPropsWithId } from './types';
import ToastContext from './ToastContext';
import { cssClampValue } from '../../utils/common';
import AudioPlayerContext from '../AudioPlayer/AudioPlayerContext';
import Toast from './Toast';
import zIndexes from '../../z-indexes';

const ToastContainer = styled.div<{ $isPlayerOpen: boolean }>`
  position: fixed;
  bottom: ${({ $isPlayerOpen }) => ($isPlayerOpen ? cssClampValue(64, 100) : 0)};
  left: 50%;
  min-width: 284px;
  margin-bottom: 20px;
  transform: translateX(-50%);
  z-index: ${zIndexes.toast};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

export default function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toastMessages, setToastMessages] = React.useState<ToastPropsWithId[]>([]);
  const toastCountRef = React.useRef(0);

  const { currentItem } = React.useContext(AudioPlayerContext);

  const showToast = React.useCallback((toastProps: ToastProps) => {
    toastCountRef.current += 1;
    const id = `toast-${toastCountRef.current}`;

    setToastMessages((prevToastMessages) => [...prevToastMessages, { id, ...toastProps }]);
  }, []);

  const hideToast = React.useCallback((id: string) => {
    setToastMessages((prevToastMessages) => prevToastMessages.filter((toast) => toast.id !== id));
  }, []);

  const toastContextValue = React.useMemo(
    () => ({
      toastMessages,
      showToast,
      hideToast,
    }),
    [toastMessages, showToast, hideToast],
  );

  return (
    <ToastContext.Provider value={toastContextValue}>
      {children}
      <Portal>
        <ToastContainer $isPlayerOpen={currentItem !== null}>
          {toastMessages.map((toastProps) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <Toast key={toastProps.id} {...toastProps} onClose={hideToast} />
          ))}
        </ToastContainer>
      </Portal>
    </ToastContext.Provider>
  );
}
