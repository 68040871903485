import * as React from 'react';
import styled, { keyframes } from 'styled-components';
import { ToastPropsWithId } from './types';
import KeypressListener, { Key } from '../KeypressListener';
import { PlainButton } from '../styled-components';
import { cssClampValue } from '../../utils/common';

export const DEFAULT_TOAST_DURATION = 5000;

export const DEFAULT_TOAST_DURATION_WITH_ACTION = 10000;

const fadeIn = keyframes`
  from {
    transform: translateY(40px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const Wrapper = styled.div<{ $isError: boolean }>`
  display: flex;
  align-items: center;
  gap: 30px;
  padding: ${cssClampValue(15, 20)} ${cssClampValue(30, 40)};
  background-color: ${({ $isError }) =>
    $isError ? 'var(--earth-fm--color--red)' : 'var(--earth-fm--color--green)'};
  color: var(--earth-fm--color--beige);
  font-size: ${cssClampValue(18, 20)};
  line-height: 1.5;
  font-weight: 700;
  border-radius: 10px;
  animation: ${fadeIn} 0.3s ease-in-out forwards;

  p {
    margin: 0;
  }
`;

const Action = styled(PlainButton)`
  margin-left: auto;
  font-size: 18px;
  font-weight: 300;
  color: var(--earth-fm--color--beige);
  text-decoration: underline;
`;

const CloseButton = styled(PlainButton)`
  svg {
    width: ${cssClampValue(16, 18)};
    height: ${cssClampValue(16, 18)};
  }
`;

interface Props extends ToastPropsWithId {
  onClose: (id: string) => void;
}

export default function Toast({
  id,
  message,
  error = false,
  duration = DEFAULT_TOAST_DURATION,
  action = null,
  onClose,
}: Props) {
  React.useEffect(() => {
    let timeoutDuration = duration || DEFAULT_TOAST_DURATION;

    if (action && !duration) {
      timeoutDuration = DEFAULT_TOAST_DURATION_WITH_ACTION;
    }

    const timer = setTimeout(() => onClose(id), timeoutDuration);

    return () => {
      clearTimeout(timer);
    };
  }, [action, duration, id, message, onClose]);

  const handleClose = React.useCallback(() => {
    onClose(id);
  }, [id, onClose]);

  return (
    <Wrapper $isError={error}>
      <KeypressListener keyCode={Key.Escape} handler={handleClose} />
      <p>{message}</p>
      {action && (
        <Action onClick={action.onClick} type="button">
          {action.content}
        </Action>
      )}
      {/* Use onMouseUp and onTouchEnd instead of onClick
       * to prevent toast onClick interference issue caused
       * when both the toast and modal are opened at the same time.
       */}
      <CloseButton type="button" onMouseUp={handleClose} onTouchEnd={handleClose}>
        <span className="screen-reader-text">Close</span>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1 1L17 17" stroke="#FFF4F2" strokeWidth="2" strokeLinecap="round" />
          <path d="M17 1L1 17" stroke="#FFF4F2" strokeWidth="2" strokeLinecap="round" />
        </svg>
      </CloseButton>
    </Wrapper>
  );
}
