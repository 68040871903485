import React from 'react';
import { ModalProps } from './Types';

interface SocialSharingModalContextData {
  showSocialSharingModal: (modalProp: ModalProps) => void;
  hideSocialSharingModal: () => void;
}

const SocialSharingModalContext = React.createContext<SocialSharingModalContextData>({
  showSocialSharingModal: () => {},
  hideSocialSharingModal: () => {},
});

export default SocialSharingModalContext;
