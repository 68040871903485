import { useState, useEffect } from 'react';

export default function useWindowFocus() {
  const [isWindowFocused, setIsWindowFocused] = useState(true);

  useEffect(() => {
    const onVisibilityChange = () => {
      setIsWindowFocused(document.visibilityState === 'visible');
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    // Set initial state
    setIsWindowFocused(document.visibilityState === 'visible');

    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);

  return isWindowFocused;
}
