import * as React from 'react';
import fetch from 'isomorphic-fetch';
import Client, { Checkout } from 'shopify-buy';

export const shopifyClient = Client.buildClient(
  {
    domain: process.env.GATSBY_SHOPIFY_STORE_URL || '',
    storefrontAccessToken: process.env.GATSBY_STOREFRONT_ACCESS_TOKEN || '',
    apiVersion: '2023-04',
  },
  // @ts-ignore - this is a workaround for a bug in shopify-buy
  fetch,
);

export interface StoreContextType {
  loading: boolean;
  client: Client;
  checkout: Checkout | null;
  isCartOpen: boolean;
  cartCount: number;
  setIsCartOpen: (isCartOpen: boolean) => void;
  addVariantToCart: (variantId: string, quantity: number) => Promise<void>;
  removeLineItem: (variantId: string) => Promise<void>;
  updateLineItem: (variantId: string, quantity: number) => Promise<void>;
}

const StoreContext = React.createContext<StoreContextType>({
  loading: false,
  client: shopifyClient,
  checkout: null,
  isCartOpen: false,
  cartCount: 0,
  setIsCartOpen: () => {},
  addVariantToCart: () => Promise.resolve(),
  removeLineItem: () => Promise.resolve(),
  updateLineItem: () => Promise.resolve(),
});

export default StoreContext;
