import * as React from 'react';
import styled from 'styled-components';
import Modal from '../Modal';
import useToast from '../ToastProvider/hooks/useToast';
import Button from '../Button';
import SocialSharingLinks from '../SocialSharingLinks';
import useHydrated from '../../hooks/useHydrated';
import { ModalProps } from './Types';
import { siteUrl } from '../../../config';

const CopyLink = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;

  input {
    width: 70%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    flex: 1 1 auto;
  }
`;

const StyledButton = styled(Button)`
  flex: 0 0 auto;
`;

export default function SocialSharingModal({ uri, title, open, onClose }: ModalProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const isBrowser = typeof window !== undefined;
  const { showToast } = useToast();
  const pageUrl = `${siteUrl}${uri}`;
  const hydrated = useHydrated();

  const copyToClipboard = React.useCallback(async () => {
    if (isBrowser && pageUrl) {
      setIsLoading(true);
      try {
        await navigator.clipboard.writeText(pageUrl);
      } catch {
        showToast({ message: 'Failed to copy link to clipboard!', error: true });
      } finally {
        setIsLoading(false);
        showToast({ message: 'Link copied to clipboard!' });
      }
    }
  }, [pageUrl, isBrowser, showToast]);

  return (
    <Modal open={open || false} title="Share" onClose={onClose}>
      {hydrated && <SocialSharingLinks uri={uri} title={title} />}
      <CopyLink>
        <input type="text" value={pageUrl} readOnly />
        <StyledButton onClick={copyToClipboard} type="button" loading={isLoading}>
          Copy Link
        </StyledButton>
      </CopyLink>
    </Modal>
  );
}
