import React from 'react';
import { ToastProps } from './types';

interface ToastContextData {
  toastMessages: ToastProps[];
  showToast: (toastProps: ToastProps) => void;
  hideToast: (id: string) => void;
}

const ToastContext = React.createContext<ToastContextData>({
  toastMessages: [],
  showToast: () => {},
  hideToast: () => {},
});

export default ToastContext;
