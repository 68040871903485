/**
 * This component logs analytics events for each 25% of the audio played.
 *
 * It also saves the total played time across sessions after each 5 seconds of audio played which is used to limit the free listening time for non-logged-in users.
 */
import * as React from 'react';
import { logPlayerEvent } from '../../utils/frontend';
import { totalPlayedStorageKey } from '../../../config';
import { getTotalPlayedTime } from '../../utils/common';

function saveTotalPlayedAcrossSessions(delta: number) {
  try {
    const { day, totalPlayed } = getTotalPlayedTime();
    localStorage.setItem(totalPlayedStorageKey, JSON.stringify({ [day]: totalPlayed + delta }));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error saving total played time:', error);
  }
}

interface Props {
  currentTime: number;
  duration: number;
  recordingId: number | null;
  playerId: string;
}

export default function LogTimePlayed({ currentTime, duration, recordingId, playerId }: Props) {
  const [totalPlayed, setTotalPlayed] = React.useState(0);
  const [lastLoggedThreshold, setLastLoggedThreshold] = React.useState(0);

  // Lock event sending for 1 second when recordingId/playerId changes.
  // This is due to a delay between these changes and when currentTime resets.
  // Without the lock, component logs all thresholds of the new recording,
  // resulting in fake events if the new recording starts beyond a threshold.
  // This lock gives the player enough time to reset currentTime,
  // effectively preventing these fake event logs.
  const locked = React.useRef(false);

  const lastCurrentTime = React.useRef(currentTime);
  const lastSavedTotalPlayed = React.useRef(totalPlayed);

  // reset state when recordingId or playerId changes
  React.useEffect(() => {
    locked.current = true;
    setTotalPlayed(0);
    setLastLoggedThreshold(0);
    lastSavedTotalPlayed.current = 0;
  }, [recordingId, playerId]);

  // unlock after one second
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      locked.current = false;
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [recordingId, playerId]);

  // update total played
  React.useEffect(() => {
    const diff = currentTime - lastCurrentTime.current;
    // only update if currentTime is increasing automatically
    // (increase should be less than one second a time) and not being set manually
    if (diff > 0 && diff < 1) {
      setTotalPlayed((prevTotal) => prevTotal + diff);
    }
    lastCurrentTime.current = currentTime;
  }, [currentTime, duration]);

  // save total played across sessions after each 5 seconds of audio played
  React.useEffect(() => {
    const diff = totalPlayed - lastSavedTotalPlayed.current;
    if (diff >= 5) {
      saveTotalPlayedAcrossSessions(Math.round(diff));
      lastSavedTotalPlayed.current = totalPlayed;
    }
  }, [totalPlayed]);

  // send analytics event for each 25% of the audio played
  React.useEffect(() => {
    if (locked.current) {
      return;
    }

    let percentagePlayed = Math.round((totalPlayed / duration) * 100);
    // round to 100% if over 99% to account for any tiny margin of error in diff calculation
    if (percentagePlayed > 99) {
      percentagePlayed = 100;
    }

    const thresholds = [25, 50, 70, 90, 100];
    const nextThreshold = thresholds.find(
      (threshold) => percentagePlayed >= threshold && threshold > lastLoggedThreshold,
    );

    if (!recordingId || nextThreshold == null) {
      return;
    }

    logPlayerEvent(`recording_${nextThreshold}`, recordingId, playerId);
    setLastLoggedThreshold(nextThreshold);
  }, [recordingId, playerId, lastLoggedThreshold, totalPlayed, duration]);

  return null;
}
