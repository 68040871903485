import React from 'react';

const EmbedTrackContext = React.createContext<{
  theme: string;
  setTheme: React.Dispatch<React.SetStateAction<string>>;
  size: number;
  setSize: React.Dispatch<React.SetStateAction<number>>;
}>({
  theme: 'green',
  setTheme: () => {},
  size: 350,
  setSize: () => {},
});

export default EmbedTrackContext;
