/**
 * A component that shows a modal to prompt the user to become a member after a certain amount of time spent on the site.
 */
import * as React from 'react';
import { pages } from '../../config';
import FirebaseContext from '../contexts/FirebaseContext';
import { Membership } from '../types';
import useHydrated from '../hooks/useHydrated';
import useWindowFocus from '../hooks/useWindowFocus';
import MembershipPopupContext from '../contexts/MembershipPopupContext';

const logTimeOnSiteIntervalKey = 'countTimeUserSpentOnSite';
const popupLastShownAtKey = 'bannerLastShownAt';
const logTimeOnSiteInterval = 10000; // 10 seconds
const showPopupAfterTimeOnSite = 1000 * 60 * 10; // 10 minutes
const hidePopupAfterShownFor = 1000 * 60 * 60 * 24 * 7; // 7 days

export default function TimedMembershipPopup() {
  const { membership, loadingMembership } = React.useContext(FirebaseContext);
  const { isMembershipModalOpen, showMembershipModal } = React.useContext(MembershipPopupContext);
  const isHydrated = useHydrated();
  const isWindowFocused = useWindowFocus();
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    if (!isHydrated) {
      return undefined;
    }

    const cleanup = () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };

    // Don't count time on site if the user is already a member
    if (loadingMembership || membership !== Membership.None) {
      return cleanup;
    }

    // Don't count time on site if the user has already seen the popup
    const lastShownAt = Number(localStorage.getItem(popupLastShownAtKey) || 0);
    if (Date.now() - lastShownAt < hidePopupAfterShownFor) {
      return cleanup;
    }

    // Don't count time on site if the user is on the membership page
    if (window.location.pathname === pages.becomeAMember) {
      return cleanup;
    }

    // Don't count time on site if the user is not focused on the window
    if (!isWindowFocused) {
      return cleanup;
    }

    // Don't count time on site if the membership modal is already open
    if (isMembershipModalOpen) {
      return cleanup;
    }

    // Count time on site and show the popup if the user has spent enough time on the site
    timerRef.current = setInterval(() => {
      const count = Number(localStorage.getItem(logTimeOnSiteIntervalKey) || 0);
      localStorage.setItem(logTimeOnSiteIntervalKey, (count + logTimeOnSiteInterval).toString());
      if (count >= showPopupAfterTimeOnSite) {
        showMembershipModal();
      }
    }, logTimeOnSiteInterval);

    return cleanup;
  }, [
    isHydrated,
    isMembershipModalOpen,
    isWindowFocused,
    loadingMembership,
    membership,
    showMembershipModal,
  ]);

  return null;
}
