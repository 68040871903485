import React from 'react';

const SearchContext = React.createContext<{
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  isSearchOpen: boolean;
  setIsSearchOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isShowingFilters: boolean;
  setIsShowingFilters: React.Dispatch<React.SetStateAction<boolean>>;
  isNavigatingWithKeyboard: boolean;
  setIsNavigatingWithKeyboard: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  query: '',
  setQuery: () => {},
  isSearchOpen: false,
  setIsSearchOpen: () => {},
  isShowingFilters: false,
  setIsShowingFilters: () => {},
  isNavigatingWithKeyboard: false,
  setIsNavigatingWithKeyboard: () => {},
});

export default SearchContext;
