import * as React from 'react';
import { Checkout } from 'shopify-buy';
import { Link } from 'gatsby';
import StoreContext, { shopifyClient } from '../contexts/StoreContext';
import useToast from './ToastProvider/hooks/useToast';
import { pages } from '../../config';
import logger from '../logger';
import FirebaseContext from '../contexts/FirebaseContext';
import { Membership } from '../types';
import { getShopEventParams, logCustomEvent } from '../utils/frontend';

const isBrowser = typeof window !== 'undefined';
const localStorageKey = 'shopify_checkout_id';

interface Props {
  children: React.ReactNode;
}

export default function StoreProvider({ children }: Props) {
  const [loading, setLoading] = React.useState(false);
  const [checkout, setCheckout] = React.useState<Checkout | null>(null);
  const [isCartOpen, setIsCartOpen] = React.useState(false);
  const [cartCount, setCartCount] = React.useState(0);

  const { showToast } = useToast();
  const { membership, user } = React.useContext(FirebaseContext);

  React.useEffect(() => {
    const initializeCheckout = async () => {
      const existingCheckoutID = isBrowser ? localStorage.getItem(localStorageKey) : null;

      if (existingCheckoutID && existingCheckoutID !== `null`) {
        try {
          const existingCheckout = await shopifyClient.checkout.fetch(existingCheckoutID);
          if (!existingCheckout.completedAt) {
            setCheckout(existingCheckout);
            return;
          }
        } catch (e) {
          localStorage.removeItem(localStorageKey);
        }
      }

      const newCheckout = await shopifyClient.checkout.create();
      setCheckout(newCheckout);
    };

    initializeCheckout();
  }, []);

  // add 20% discount for logged in members
  React.useEffect(() => {
    if (!checkout?.id) {
      return;
    }

    if (membership !== Membership.None) {
      const discountCode = process.env.GATSBY_MEMBERSHIP_DISCOUNT_CODE || 'YFA0V8QQEVG8';
      shopifyClient.checkout
        .addDiscount(checkout.id, discountCode)
        .then((res) => {
          setCheckout(res);
        })
        .catch((e) => {
          logger.error('Error adding discount code', e);
        });
    } else {
      shopifyClient.checkout
        .removeDiscount(checkout.id)
        .then((res) => {
          setCheckout(res);
        })
        .catch((e) => {
          logger.error('Error removing discount code', e);
        });
    }
  }, [checkout?.id, membership]);

  React.useEffect(() => {
    if (!checkout?.id) {
      return;
    }

    if (user?.email) {
      shopifyClient.checkout
        .updateEmail(checkout.id, user.email)
        .then((res) => {
          setCheckout(res);
        })
        .catch((e) => {
          logger.error('Checkout: Error setting user email', e);
        });
    } else {
      shopifyClient.checkout
        .updateEmail(checkout.id, '')
        .then((res) => {
          setCheckout(res);
        })
        .catch((e) => {
          logger.error('Checkout: Error unsetting user email', e);
        });
    }
  }, [checkout?.id, user?.email]);

  // save checkoutId to localStorage
  React.useEffect(() => {
    if (isBrowser && checkout) {
      localStorage.setItem(localStorageKey, checkout.id);
    }
  }, [checkout]);

  // update cartCount
  React.useEffect(() => {
    if (checkout) {
      let count = 0;
      checkout.lineItems.forEach((item) => {
        count += item.quantity;
      });
      setCartCount(count);
    } else {
      setCartCount(0);
    }
  }, [checkout]);

  const addVariantToCart = React.useCallback(
    async (variantId: string, quantity: number) => {
      if (loading || !checkout) {
        return;
      }

      setLoading(true);

      const checkoutID = checkout.id;

      const lineItemsToUpdate = [
        {
          variantId,
          quantity,
        },
      ];

      try {
        const newCheckout = await shopifyClient.checkout.addLineItems(
          checkoutID,
          lineItemsToUpdate,
        );
        setCheckout(newCheckout);
        showToast({
          message: (
            <>
              Added to <Link to={pages.cart}>cart</Link>!
            </>
          ),
        });
        const newLineItem = newCheckout.lineItems.find((item) => item.variant?.id === variantId);
        if (newLineItem) {
          logCustomEvent(
            'add_to_cart',
            getShopEventParams(newCheckout.id, newCheckout.currencyCode, [newLineItem]),
          );
        }
      } catch (error) {
        showToast({
          message: 'Error adding item to cart',
          error: true,
        });
      }

      setLoading(false);
    },
    [checkout, loading, showToast],
  );

  const removeLineItem = React.useCallback(
    async (lineItemID: string) => {
      if (loading || !checkout) {
        return;
      }

      setLoading(true);

      try {
        const newCheckout = await shopifyClient.checkout.removeLineItems(checkout.id, [lineItemID]);
        setCheckout(newCheckout);
        const removedLineItem = checkout.lineItems.find((item) => item.id === lineItemID);
        if (removedLineItem) {
          logCustomEvent(
            'remove_from_cart',
            getShopEventParams(newCheckout.id, newCheckout.currencyCode, [removedLineItem]),
          );
        }
      } catch (error) {
        showToast({
          message: 'Error removing item from cart',
          error: true,
        });
      }
      setLoading(false);
    },
    [checkout, loading, showToast],
  );

  const updateLineItem = React.useCallback(
    async (lineItemID: string, quantity: number) => {
      if (loading || !checkout) {
        return;
      }

      setLoading(true);

      try {
        const lineItemsToUpdate = [{ id: lineItemID, quantity }];

        const newCheckout = await shopifyClient.checkout.updateLineItems(
          checkout.id,
          lineItemsToUpdate,
        );
        setCheckout(newCheckout);
        const updatedLineItem = newCheckout.lineItems.find((item) => item.id === lineItemID);
        if (updatedLineItem) {
          logCustomEvent(
            'update_item_in_cart',
            getShopEventParams(newCheckout.id, newCheckout.currencyCode, [updatedLineItem]),
          );
        }
      } catch (error) {
        showToast({
          message: 'Error updating item in cart',
          error: true,
        });
      }
      setLoading(false);
    },
    [checkout, loading, showToast],
  );

  const contextValue = React.useMemo(
    () => ({
      loading,
      client: shopifyClient,
      checkout,
      isCartOpen,
      cartCount,
      setIsCartOpen,
      addVariantToCart,
      removeLineItem,
      updateLineItem,
    }),
    [addVariantToCart, cartCount, checkout, isCartOpen, loading, removeLineItem, updateLineItem],
  );

  return <StoreContext.Provider value={contextValue}>{children}</StoreContext.Provider>;
}
