/**
 * Utility functions for the frontend
 */
import { FirebaseError } from 'firebase/app';
import { sendSignInLinkToEmail, User } from 'firebase/auth';
import {
  arrayRemove,
  arrayUnion,
  collection,
  doc,
  DocumentData,
  DocumentReference,
  DocumentSnapshot,
  getDoc,
  getDocs,
  PartialWithFieldValue,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { navigate } from 'gatsby';
import ky from 'ky';
import { logEvent } from 'firebase/analytics';
import { CheckoutLineItem } from 'shopify-buy';
import { httpsCallable } from 'firebase/functions';
import { apiUrls, isLiveEnv, pages, storageKeys } from '../../config';
import { RecordingFiltersObj } from '../components/RecordingFilters';
import { analytics, auth, db, functions } from '../firebase';
import logger from '../logger';
import {
  MemberPlaylist,
  MemberProfileData,
  Membership,
  Playlist,
  Podcast,
  PromoCodeProps,
  RecordingPreviewData,
  ScholarshipId,
} from '../types';
import { notEmpty, recordingToPlaylistItem } from './common';
import { MembershipPlanType } from '../components/MembershipPlans/types';
import fetchWithCache from './fetchWithCache';

export function checkIfDesktopApp(): boolean {
  const userAgent = typeof window === 'undefined' ? '' : window.navigator.userAgent;
  return userAgent.includes('Electron') && userAgent.includes('earth.fm');
}

export async function emailSignInLink(email: string) {
  const isDesktopApp = checkIfDesktopApp();
  await sendSignInLinkToEmail(auth, email, {
    url: isDesktopApp
      ? `${window.location.origin}${pages.desktopAppSignIn}`
      : window.location.origin,
    handleCodeInApp: true,
  });
}

export async function getMembership(user: User | null): Promise<Membership> {
  if (!user) {
    return Membership.None;
  }

  const decodedToken = await user.getIdTokenResult(true);
  const { stripeRole } = decodedToken.claims;

  switch (stripeRole) {
    case 'individual_monthly':
    case 'individual_yearly':
      return Membership.Individual;

    case 'family_monthly':
    case 'family_yearly':
      return Membership.Family;

    // Below are tje legacy membership plans,
    // keeping it for the existing members
    case 'friend':
    case 'supporter':
      return Membership.Friend;

    case 'partner':
      return Membership.Partner;

    default:
      break;
  }

  return Membership.None;
}

/**
 * Automatically redirect the user after sign-in
 *
 * If user is redirected from scholarship page, redirect to the scholarship page.
 * If user is signed in, and has no membership, redirect to the membership page.
 * If user is signed in, and has a membership, redirect to the home page.
 * If user is not signed in, do nothing.
 */
export async function autoRedirectAfterSignIn(user: User | null) {
  const scholarshipStorageValue = window.localStorage.getItem(storageKeys.scholarship);
  const giftCardStorageValue = window.localStorage.getItem(storageKeys.giftCard);

  if (!user) {
    return;
  }

  let membership: Membership | null = null;

  try {
    membership = await getMembership(user);
  } catch (error) {
    logger.error('Error getting user membership', error);
  }

  if (scholarshipStorageValue && scholarshipStorageValue === 'scholarship_requested') {
    // redirect to the scholarship page if the user is sign in to request scholarship
    navigate(pages.scholarship, { replace: true });
    window.localStorage.removeItem(storageKeys.scholarship);
    return;
  }

  if (giftCardStorageValue) {
    // if the user is redirected from the gift card link and signed in with email
    // redirect to the sign in process with the gift card params
    // to create Stripe checkout session with promotion code
    const { membershipType, promotionCode } = JSON.parse(giftCardStorageValue);
    if (membershipType && promotionCode) {
      navigate(
        `${pages.signIn}?redirectFrom=giftCard&membershipType=${membershipType}&promotionCode=${promotionCode}`,
        { replace: true },
      );
      window.localStorage.removeItem(storageKeys.giftCard);
    }
    return;
  }

  if (membership && membership === Membership.None) {
    navigate(pages.becomeAMember, { replace: true });
  } else {
    navigate(pages.home, { replace: true }); // remove sign-in query params from the url
  }
}

export async function getMemberProfileData(uid: string): Promise<MemberProfileData | null> {
  if (!uid) {
    return null;
  }

  const snap = await getDoc(doc(db, 'members', uid, 'public', 'profile'));
  if (snap.exists()) {
    return {
      ...snap.data(),
      id: uid,
    } as MemberProfileData;
  }
  return null;
}

export async function updateMemberProfileData(
  uid: string,
  data: PartialWithFieldValue<MemberProfileData>,
): Promise<void> {
  if (!uid || !data) {
    return;
  }

  await setDoc(doc(db, 'members', uid, 'public', 'profile'), data, { merge: true });
}

export async function fileToDataUrl(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });
}

export async function getImageSizeFromDataUrl(
  dataUrl: string,
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = reject;
    img.src = dataUrl;
  });
}

export async function subscribeToNewsletter(email: string, url = apiUrls.newsletterSubscription) {
  const result = await ky
    .post(url, {
      json: { email },
    })
    .json<{ success: boolean }>();

  if (!result.success) {
    throw new Error('Something went wrong!');
  }
}

export function playlistRef(playlistId: string): DocumentReference<DocumentData> {
  return doc(db, 'playlists', playlistId);
}

export function favoritesRef(memberId: string): DocumentReference<DocumentData> {
  return doc(db, 'members', memberId, 'private', 'favorites');
}
export function getRecordingData(id: number, timestamp = 1): Promise<RecordingPreviewData | null> {
  return fetchWithCache<RecordingPreviewData>(`/static-api/recordings/${id}.json?v=${timestamp}`);
}

export async function getAllRecordingsData(v = 1): Promise<RecordingPreviewData[]> {
  const data = await fetchWithCache<RecordingPreviewData[]>(
    `/static-api/recordings/all.json?v=${v}`,
  );
  return data ?? [];
}

export function getPodcastData(id: number, timestamp = 1): Promise<Podcast | null> {
  return fetchWithCache<Podcast>(`/static-api/podcasts/${id}.json?v=${timestamp}`);
}

export async function getAllPodcastsData(v = 1): Promise<Podcast[]> {
  const data = await fetchWithCache<Podcast[]>(`/static-api/podcasts/all.json?v=${v}`);
  return data ?? [];
}

export function getEarthFmPlaylist(id: string, timestamp = 1): Promise<Playlist | null> {
  return fetchWithCache<Playlist>(`/static-api/playlists/${id}.json?v=${timestamp}`);
}

export async function getAllEarthFmPlaylists(v = 1): Promise<Playlist[]> {
  const data = await fetchWithCache<Playlist[]>(`/static-api/playlists/all.json?v=${v}`);
  return data ?? [];
}

export async function memberPlaylistToPlaylist(data: MemberPlaylist): Promise<Playlist | null> {
  const owner = await getMemberProfileData(data.owner);

  if (!owner) {
    logger.error(
      'Error getting playlist owner',
      new Error(`Playlist ${data.id} has an invalid owner: ${data.owner}`),
    );
    return null;
  }

  const recordings = await Promise.all((data.items || []).map(getRecordingData));
  const items = recordings.map((recording) => {
    if (!recording) {
      return null;
    }
    return recordingToPlaylistItem(recording);
  });

  return {
    id: data.id,
    title: data.name,
    subtitle: data.description,
    thumbnail: data.thumbnail,
    public: data.public,
    owner: {
      id: data.owner,
      name: owner?.name || 'Unknown',
    },
    uri: `${pages.memberPlaylistBase}${data.id}`,
    items: items.filter(notEmpty),
  } as Playlist;
}

export async function getMemberPlaylist(id: string): Promise<MemberPlaylist | null> {
  let snap: DocumentSnapshot;
  try {
    snap = await getDoc(playlistRef(id));
  } catch (error) {
    if (error instanceof FirebaseError && error.code === 'permission-denied') {
      if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line no-console
        console.warn('Permission denied when getting playlist', error);
      }
      // do nothing on production
      // this most commonly happens when a previously shared playlist by someone is no longer public
      return null;
    }
    throw error;
  }
  if (snap.exists()) {
    const data = snap.data() as Omit<MemberPlaylist, 'id'>;
    return { ...data, id: snap.id };
  }
  return null;
}

export async function getEnrichedMemberPlaylist(id: string): Promise<Playlist | null> {
  const memberPlaylist = await getMemberPlaylist(id);
  if (memberPlaylist) {
    const playlist = await memberPlaylistToPlaylist(memberPlaylist);
    return playlist;
  }
  return null;
}

export async function getAllMemberPlaylists(uid: string): Promise<MemberPlaylist[]> {
  const querySnapshot = await getDocs(
    query(collection(db, 'playlists'), where('owner', '==', uid)),
  );
  const memberPlaylists: MemberPlaylist[] = [];
  querySnapshot.forEach((snap) => {
    memberPlaylists.push({ id: snap.id, ...(snap.data() as Omit<MemberPlaylist, 'id'>) });
  });
  return memberPlaylists;
}

export async function addItemToPlaylist(playlistId: string, recordingId: number): Promise<void> {
  await updateDoc(playlistRef(playlistId), {
    items: arrayUnion(recordingId),
  });
}

export async function removeItemFromPlaylist(
  playlistId: string,
  recordingId: number,
): Promise<void> {
  await updateDoc(playlistRef(playlistId), {
    items: arrayRemove(recordingId),
  });
}

export async function addItemToLibrary(
  collectionName: 'recordings' | 'playlists' | 'podcasts',
  itemId: number | string,
  memberId: string,
): Promise<void> {
  const docRef = favoritesRef(memberId);
  const docSnap = await getDoc(docRef);
  if (!docSnap.exists()) {
    await setDoc(docRef, {
      [collectionName]: [itemId],
    });
  } else {
    await updateDoc(docRef, {
      [collectionName]: arrayUnion(itemId),
    });
  }
}

export async function removeItemFromLibrary(
  collectionName: 'recordings' | 'playlists' | 'podcasts',
  itemId: number | string,
  memberId: string,
): Promise<void> {
  await updateDoc(favoritesRef(memberId), {
    [collectionName]: arrayRemove(itemId),
  });
}

export async function safeAudioPlay(playResponse: Promise<void> | void): Promise<void> {
  if (playResponse instanceof Promise) {
    playResponse.catch(() => {
      // empty catch to avoid unhandled promise rejection error
    });
  }
}

export function filterRecordings<
  Recording extends {
    mood: number | null;
    region: string | null;
    predominantSound: number | null;
    habitat: number | null;
    recordist: number[] | null;
    duration: number;
  },
>(recordings: Recording[], filters: RecordingFiltersObj): Recording[] {
  return recordings
    .filter((recording) => {
      if (filters.mood.length > 0) {
        return recording.mood && filters.mood.includes(recording.mood);
      }
      return true;
    })
    .filter((recording) => {
      if (filters.predominantSound.length > 0) {
        return (
          recording.predominantSound &&
          filters.predominantSound.includes(recording.predominantSound)
        );
      }
      return true;
    })
    .filter((recording) => {
      if (filters.habitat.length > 0) {
        return recording.habitat && filters.habitat.includes(recording.habitat);
      }
      return true;
    })
    .filter((recording) => {
      if (filters.region.length > 0) {
        return recording.region && filters.region.includes(recording.region);
      }
      return true;
    })
    .filter((recording) => {
      if (filters.recordist.length > 0) {
        return (
          recording.recordist && recording.recordist.some((r) => filters.recordist.includes(r))
        );
      }
      return true;
    })
    .filter((recording) => {
      if (filters.duration.length > 0) {
        return filters.duration.some((durationRange) => {
          const [startStr, endStr] = durationRange.split('-');
          const start = Number.parseInt(startStr, 10) * 60;
          const end = Number.parseInt(endStr, 10) * 60;
          return start <= recording.duration && recording.duration <= end;
        });
      }
      return true;
    });
}

export function recordingRawDataToPreviewData(
  recording: Queries.RecordingCardDataFragment,
): RecordingPreviewData | null {
  const { id, title, uri, featuredImage, recordingSettings } = recording;

  if (
    !id ||
    !title ||
    !uri ||
    !recordingSettings?.audioUrl ||
    !recordingSettings?.audio?.mediaDetails ||
    !recordingSettings.recordists
  ) {
    return null;
  }

  return {
    id,
    title,
    uri,
    userFriendlyTitle: recordingSettings.userFriendlyTitle || null,
    featuredImage: featuredImage?.node.localFile?.childImageSharp?.gatsbyImageData || null,
    duration: recordingSettings.audio.mediaDetails.length || 0,
    isLivestream: recordingSettings.isLivestream || false,
    audio: recordingSettings.audioUrl,
    recordists: recordingSettings.recordists.filter(notEmpty),
    location: null,
    habitat: null,
    mood: null,
    predominantSound: null,
  };
}

export function formatPrice(price: number, currency: string): string {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  });
  return formatter.format(price);
}

export function logCustomEvent(eventName: string, eventParams?: Record<string, unknown>): void {
  // only log events on production
  if (analytics && window.location.hostname === 'earth.fm') {
    logEvent(analytics, eventName, eventParams);
  } else {
    // eslint-disable-next-line no-console
    console.debug('logCustomEvent', eventName, eventParams);
  }
}

export function getMembershipEventParams(plan: MembershipPlanType, transactionId?: string) {
  return {
    transaction_id: transactionId,
    currency: 'USD',
    value: plan.price,
    shipping: 0,
    tax: 0,
    items: [
      {
        item_id: plan.id,
        item_name: plan.name,
        quantity: 1,
        price: plan.price,
        affiliation: 'earth.fm',
        item_brand: 'earth.fm',
        item_category: 'Membership',
      },
    ],
  };
}

export function getShopEventParams(
  checkoutId: string,
  currency: string,
  lineItems: CheckoutLineItem[],
) {
  return {
    transaction_id: checkoutId,
    currency,
    // the total value of the relevant items
    value: lineItems.reduce(
      (total, item) => total + (item.unitPrice?.amount ?? 0) * item.quantity,
      0,
    ),
    items: lineItems.map((lineItem) => [
      {
        item_id: lineItem.id,
        item_name: lineItem.title,
        quantity: lineItem.quantity,
        price: (lineItem.unitPrice?.amount ?? 0) * lineItem.quantity,
        affiliation: 'earth.fm',
        item_brand: 'earth.fm',
        item_category: 'Shop',
      },
    ]),
  };
}

export function logAuthEvent(isNewUser: boolean, user: User): void {
  if (isNewUser) {
    logCustomEvent('sign_up', {
      method: user.providerId,
    });
  } else {
    logCustomEvent('login');
  }
}

export function logPlayerEvent(
  eventName: string,
  recordingId: number,
  eventSource: string,
  additionalParams: Record<string, unknown> = {},
) {
  getRecordingData(recordingId).then((recordingData) => {
    if (!recordingData) {
      return;
    }

    logCustomEvent(eventName, {
      author: recordingData.recordists.map((recordist) => recordist.title).join(', '),
      title: recordingData.title,
      duration: recordingData.duration,
      mood: recordingData.mood,
      sound: recordingData.predominantSound,
      habitat: recordingData.habitat,
      event_source: eventSource,
      ...additionalParams,
    });
  });
}

export function getVisiblePlaylists(allPlaylists: Readonly<Queries.PlaylistCardDataFragment[]>) {
  // hide playlists that are marked as hidden in production
  if (isLiveEnv) {
    return allPlaylists.filter((playlist) => !playlist.playlistSettings?.hideFromProduction);
  }

  return allPlaylists;
}

export async function getStripePromotionCode(
  scholarshipId: ScholarshipId,
  email: string,
): Promise<PromoCodeProps | null> {
  if (!scholarshipId || !email || email === '') {
    return null;
  }

  try {
    const createPromotionCode = httpsCallable<
      {
        email: string;
        scholarshipId: ScholarshipId;
      },
      { promoCode: PromoCodeProps }
    >(functions, 'createScholarshipCode');

    const result = await createPromotionCode({
      email,
      scholarshipId,
    });

    const { promoCode } = result.data;
    return promoCode;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error getting promotion code', error);
    return null;
  }
}

export function updateScholarshipStorageValue(request: string): void {
  const scholarshipStorageValue = window.localStorage.getItem(storageKeys.scholarship);
  const isUserFinishingSignInWithEmail = window.localStorage.getItem('emailForSignIn');

  switch (request) {
    case 'reset':
      if (scholarshipStorageValue && !isUserFinishingSignInWithEmail) {
        window.localStorage.removeItem(storageKeys.scholarship);
      }
      break;
    case 'scholarshipRequested':
      window.localStorage.setItem(storageKeys.scholarship, 'scholarship_requested');
      break;
    default:
      // eslint-disable-next-line no-console
      console.error('Invalid request:', request);
      break;
  }
}

export function shuffleArray<T>(array: T[]) {
  const shuffledArray = [...array];
  for (let i = shuffledArray.length - 1; i > 0; i -= 1) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
  }
  return shuffledArray;
}
