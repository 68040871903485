import * as React from 'react';
import styled from 'styled-components';
import AudioPlayerContext from './AudioPlayerContext';
import PlayIcon from './icons/play.inline.svg';
import PauseIcon from './icons/pause.inline.svg';
import { PlainButton } from '../styled-components';

const Button = styled(PlainButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--earth-fm--color--green);
  background: #fff;
  border-radius: 50%;
  width: 32px;
  height: 32px;
`;

const PlayIconFix = styled.span`
  display: inline-block;
  width: 2px;
`;

interface Props {
  className?: string;
  onPlay?: () => void;
}

export default function PlayPauseButton({ className = undefined, onPlay = undefined }: Props) {
  const { state, play, pause } = React.useContext(AudioPlayerContext);
  const isPlaying = React.useMemo(() => state === 'playing' || state === 'buffering', [state]);

  const handleClick = React.useCallback(() => {
    if (isPlaying) {
      pause();
    } else {
      play();
      if (onPlay) {
        onPlay();
      }
    }
  }, [isPlaying, onPlay, pause, play]);

  return (
    <Button
      className={className}
      type="button"
      aria-label={isPlaying ? 'Pause' : 'Play'}
      onClick={handleClick}
    >
      {!isPlaying ? <PlayIconFix /> : null}
      {isPlaying ? <PauseIcon /> : <PlayIcon />}
    </Button>
  );
}
