import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  --track-height: 4px;
  --track-color: rgba(255, 255, 255, 0.2);
  --track-fill-color: #fff;
  --thumb-size: 10px;
  --thumb-color: #fff;
  --thumb-opacity: 1;
  position: relative;
`;

const Fill = styled.span`
  position: absolute;
  left: 0;
  top: calc((var(--thumb-size) - var(--track-height)) / 2);
  width: 0;
  height: var(--track-height);
  background: var(--track-fill-color);
  pointer-events: none !important;
  border-radius: calc(var(--track-height) / 2);
  transition: height 0.2s ease-in;
  transition-property: height, top;
`;

const Input = styled.input`
  display: block;
  width: 100%;
  height: max(var(--track-height), var(--thumb-size));
  -webkit-appearance: none;
  appearance: none;
  background: transparent; /* Otherwise white in Chrome */
  transition: all 0.2s ease-in;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: auto;
    outline-color: var(--earth-fm--color--blue);
  }

  &::-webkit-slider-runnable-track {
    height: var(--track-height);
    background: var(--track-color);
    border-radius: calc(var(--track-height) / 2);
    transition: all 0.2s ease-in;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: var(--thumb-size);
    width: var(--thumb-size);
    margin-top: calc(-1 * ((var(--thumb-size) - var(--track-height)) / 2));
    background: var(--thumb-color);
    border-radius: 50%;
    opacity: var(--thumb-opacity);
    transition: all 0.2s ease;
  }

  &:focus::-webkit-slider-thumb {
    outline: 1px solid var(--thumb-color);
    outline-offset: 1px;
  }

  &::-moz-range-track {
    height: var(--track-height);
    background: var(--track-color);
    border-radius: calc(var(--track-height) / 2);
    transition: all 0.2s ease-in;
  }

  &::-moz-range-thumb {
    height: var(--thumb-size);
    width: var(--thumb-size);
    border: 0;
    background: var(--thumb-color);
    border-radius: 50%;
    opacity: var(--thumb-opacity);
    transition: all 0.2s ease;
  }

  &:focus::-moz-range-thumb {
    outline: 1px solid var(--thumb-color);
    outline-offset: 1px;
  }
`;

interface Props {
  inputId: string;
  value: number;
  min: number;
  max: number;
  step?: number;
  className?: string;
  onChange: (newValue: number) => void;
  onKeyDown?: boolean;
}

export default function SliderControl({
  inputId,
  value,
  min,
  max,
  step = 0.01,
  className = undefined,
  onChange,
  onKeyDown = false,
}: Props) {
  const [activeValue, setActiveValue] = React.useState(value);
  const [isKeyDown, setIsKeyDown] = React.useState(onKeyDown);

  const sliderLockRef = React.useRef(false);

  const enableSliderLock = React.useCallback(() => {
    sliderLockRef.current = true;
  }, []);

  const disableSliderLock = React.useCallback(() => {
    sliderLockRef.current = false;
  }, []);

  const handleChange = React.useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const numericValue = Number.parseFloat(event.currentTarget.value);
      setActiveValue(numericValue);
      onChange(numericValue);
    },
    [onChange],
  );

  const increaseStep = React.useCallback(() => {
    if (isKeyDown === undefined) {
      return;
    }
    enableSliderLock();
    setIsKeyDown(true);
  }, [enableSliderLock, isKeyDown]);

  const decreaseStep = React.useCallback(() => {
    if (isKeyDown === undefined) {
      return;
    }
    setIsKeyDown(false);
    disableSliderLock();
  }, [isKeyDown, disableSliderLock]);

  const effectiveValue = sliderLockRef.current ? activeValue : value;

  return (
    <Wrapper className={className}>
      <Fill
        style={{
          width: `${
            max > 0 ? (Math.min(value / max, 1) + (value / max < 0.5 ? 0.01 : 0)) * 100 : 0
          }%`,
        }}
      />
      <label htmlFor={inputId} className="screen-reader-text">
        {inputId}
      </label>
      <Input
        id={inputId}
        type="range"
        min={min}
        max={max}
        step={isKeyDown ? 10 : step}
        value={effectiveValue}
        onChange={handleChange}
        onMouseDown={enableSliderLock}
        onMouseUp={disableSliderLock}
        onTouchStart={enableSliderLock}
        onTouchEnd={disableSliderLock}
        onKeyDown={increaseStep}
        onBlur={decreaseStep}
      />
    </Wrapper>
  );
}
