import * as React from 'react';
import styled from 'styled-components';
import { getAdditionalUserInfo, isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { Link } from 'gatsby';
import { ErrorMessage } from './styled-components';
import { cssClampValue } from '../utils/common';
import { autoRedirectAfterSignIn, logAuthEvent } from '../utils/frontend';
import TextField from './TextField';
import { auth } from '../firebase';
import FirebaseContext from '../contexts/FirebaseContext';
import logger from '../logger';
import Modal from './Modal';
import Button from './Button';
import { pages } from '../../config';

const isBrowser = typeof window !== 'undefined';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${cssClampValue(20, 30)};

  p {
    margin: 0;
  }

  > * {
    width: 100%;
  }
`;

const SubmitButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-end;
`;

export default function FinishSignInWithEmail() {
  const [email, setEmail] = React.useState(
    isBrowser ? window.localStorage.getItem('emailForSignIn') : null,
  );
  const [emailFieldValue, setEmailFieldValue] = React.useState('');
  const [isError, setIsError] = React.useState(false);
  const [isSigningIn, setIsSigningIn] = React.useState(false);

  const { initializing, user } = React.useContext(FirebaseContext);

  React.useEffect(() => {
    const signInLink = window.location.href;
    if (!isSignInWithEmailLink(auth, signInLink)) {
      return;
    }

    if (signInLink.includes(pages.desktopAppSignIn)) {
      // If the user is signing in from the desktop app, we don't want to show the email form
      return;
    }

    if (email) {
      setIsSigningIn(true);
      setIsError(false);
      signInWithEmailLink(auth, email, signInLink)
        .then((userCredentials) => {
          setEmail(null);
          setEmailFieldValue('');
          setIsError(false);
          window.localStorage.removeItem('emailForSignIn');
          autoRedirectAfterSignIn(userCredentials.user);
          const additionalUserInfo = getAdditionalUserInfo(userCredentials);
          if (additionalUserInfo) {
            logAuthEvent(additionalUserInfo.isNewUser, userCredentials.user);
          }
        })
        .catch((error) => {
          // TODO: better handle auth errors such as auth/invalid-email
          setIsError(true);
          logger.error('Error finishing signing in with email link', error);
        })
        .finally(() => {
          setIsSigningIn(false);
        });
    }
  }, [email]);

  const handleFormSubmit = React.useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setEmail(emailFieldValue);
    },
    [emailFieldValue],
  );

  const cancel = React.useCallback(() => {
    window.localStorage.removeItem('emailForSignIn');
    window.location.href = pages.home;
  }, []);

  if (!isBrowser || !isSignInWithEmailLink(auth, window.location.href) || user || initializing) {
    return null;
  }

  if (window.location.href.includes(pages.desktopAppSignIn)) {
    return null;
  }

  const showForm = !email || isError;

  return (
    <Modal
      open
      title={email ? 'Signing in with email' : 'Finish signing in with email'}
      loading={!showForm && isSigningIn}
    >
      {showForm ? (
        <Form onSubmit={handleFormSubmit}>
          <p>Please provide your email for confirmation</p>
          <TextField
            label="Email"
            id="user-email"
            type="email"
            placeholder="user@example.com"
            required
            value={emailFieldValue}
            onChange={setEmailFieldValue}
          />
          {isError ? (
            <ErrorMessage>
              Uh-oh, something went wrong! Please try{' '}
              <Link to={pages.signIn} replace>
                signing in
              </Link>{' '}
              again.
            </ErrorMessage>
          ) : null}
          <SubmitButtonWrapper>
            <Button type="button" secondary onClick={cancel}>
              Cancel
            </Button>
            <Button type="submit" loading={isSigningIn}>
              Continue
            </Button>
          </SubmitButtonWrapper>
        </Form>
      ) : null}
    </Modal>
  );
}
