import React from 'react';
import { User } from 'firebase/auth';
import { Favorites, MemberProfileData, Membership } from '../types';

interface FirebaseContextData {
  initializing: boolean;
  user: User | null;
  loadingMembership: boolean;
  membership: Membership;
  loadingMember: boolean;
  member: MemberProfileData | null;
  loadingFavorites: boolean;
  favorites: Favorites;
}

const FirebaseContext = React.createContext<FirebaseContextData>({
  initializing: true,
  user: null,
  loadingMembership: true,
  membership: Membership.None,
  loadingMember: true,
  member: null,
  loadingFavorites: true,
  favorites: {
    recordings: [],
    playlists: [],
    podcasts: [],
  },
});

export default FirebaseContext;
