/**
 * Common utility functions to use in both gatsby-node.js and frontend code
 *
 * @note This file should not have any firebase or other frontend dependencies (e.g. ky or fetch)
 */
import { frontendHost, pages, totalPlayedStorageKey } from '../../config';
import { PlaybackItem, PlaylistItem, RecordingPreviewData, LatestItem } from '../types';

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

/**
 * Converts seconds to human readable hh:mm:ss format
 *
 * @note doesn't work if the input number (seconds) is >= 86400 (seconds in 24 hours)
 */
export function secondsToHhMmSs(seconds: number): string {
  const dateString = new Date(seconds * 1000).toISOString();
  if (seconds < 3600) {
    return dateString.slice(14, 19);
  }
  return dateString.slice(11, 19);
}

export function secondsToApproxHourMinutes(seconds: number): string {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);

  if (h > 0) {
    return `${h}h ${m}m`;
  }

  return `${m}m`;
}

export function recordingToPlaybackItem(recording: RecordingPreviewData): PlaybackItem {
  return {
    id: recording.id,
    title: recording.title,
    userFriendlyTitle: recording.userFriendlyTitle || null,
    uri: recording.uri,
    recordists: recording.recordists,
    sourceUrl: recording.audio,
    coverImg: recording.featuredImage,
    duration: recording.duration,
    isLivestream: recording.isLivestream,
  };
}

export function recordingToPlaylistItem(recording: RecordingPreviewData): PlaylistItem {
  return {
    ...recordingToPlaybackItem(recording),
    habitat: recording.habitat,
    location: recording.location,
  };
}

export function refineCoordinateData(
  coordinates: { lat: number | null; lng: number | null } | null | undefined,
): { lat: number; lng: number } | null {
  if (!coordinates || coordinates.lat === null || coordinates.lng === null) {
    return null;
  }
  return { lat: coordinates.lat, lng: coordinates.lng };
}

export function cssClampValue(min: number, max: number, base = 768) {
  return `clamp(${min}px, ${(((min + max) / 2 / base) * 100).toFixed(2)}vw, ${max}px)`;
}

export function postDateFormat(dateStr: string): string {
  const date = new Date(dateStr);
  if (Number.isNaN(date.getDate())) {
    return '';
  }

  const [day, month, year] = date
    .toLocaleDateString('en-GB', { month: 'long', day: 'numeric', year: 'numeric' })
    .split(' ');
  const now = new Date();

  if (date.getFullYear() !== now.getFullYear()) {
    return `${month.slice(0, 3)} ${day}, ${year}`;
  }

  return `${month.slice(0, 3)}, ${day}`;
}

/**
 * return date in format: MMM, YYYY
 */
export function simplifiedDateFormat(dateStr: string): string {
  const date = new Date(dateStr);
  if (Number.isNaN(date.getDate())) {
    return '';
  }

  const [month, year] = date
    .toLocaleDateString('en-GB', { month: 'long', year: 'numeric' })
    .split(' ');

  return `${month.slice(0, 3)}, ${year}`;
}

export function isOrHasParentOfType(node: Node, type: string): boolean {
  if (type !== 'BODY' && node.nodeName === 'BODY') return false;
  if (type === node.nodeName) return true;

  return node.parentNode ? isOrHasParentOfType(node.parentNode, type) : false;
}

export function getSeoFriendlyAlt(title: string | null | undefined) {
  return title ? `${title} - ${frontendHost}` : '';
}

/**
 * Returns a randomly selected item from the array
 */
export function arraySample<T>(arr: Array<T>): T {
  return arr[Math.floor(Math.random() * arr.length)];
}

export function isNumeric(value: string) {
  return /^\d+$/.test(value);
}

export function getProductUrl(handle: string): string {
  return `${pages.shop}${handle}/`;
}

export function getRelatedProductIds(
  metafields: {
    namespace: string;
    key: string;
    value: string;
  }[],
): string[] {
  try {
    const relatedProductsMetafield = metafields.filter(
      (metafield) =>
        metafield.namespace === 'shopify--discovery--product_recommendation' &&
        metafield.key === 'related_products',
    );

    if (!relatedProductsMetafield.length) {
      return [];
    }

    const products = JSON.parse(relatedProductsMetafield[0].value);
    return products;
  } catch (e) {
    return [];
  }
}
/**
 * Count latest items created within the previous 30 days
 */
export function countLatestItems(items: Readonly<LatestItem[]>): number {
  if (items.length === 0) return 0;

  const today = new Date();
  const aMonthAgo = new Date(new Date().setDate(today.getDate() - 30));

  const count = items.filter((item) => {
    if (item.date) {
      const createdAt = new Date(item.date);
      return createdAt >= aMonthAgo;
    }

    if (item.memberSince) {
      const createdAt = new Date(item.memberSince);
      return createdAt >= aMonthAgo;
    }

    return false;
  }).length;

  return count;
}

/**
 * Returns the total played time across all sessions for the current day.
 *
 * This time is calculated and saved in local storage by the LogTimePlayed component.
 */
export function getTotalPlayedTime(): { day: string; totalPlayed: number } {
  const today = new Date().toISOString().slice(0, 10).replace(/-/g, '');
  if (typeof localStorage === 'undefined') {
    return { day: today, totalPlayed: 0 };
  }

  let totalPlayedObj: {
    [key: string]: number;
  } = {};
  try {
    totalPlayedObj = JSON.parse(localStorage.getItem(totalPlayedStorageKey) || '{}');
    if (!totalPlayedObj || typeof totalPlayedObj !== 'object') {
      totalPlayedObj = {};
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error parsing total played time:', error);
  }

  const totalPlayed =
    today in totalPlayedObj && typeof totalPlayedObj[today] === 'number'
      ? totalPlayedObj[today]
      : 0;

  return { day: today, totalPlayed };
}

/**
 * Returns a rounded count for the total number of items
 */
export function RoundedRecordingCount(totalCount: number): string {
  if (totalCount < 100) {
    return totalCount.toString(); // Return the exact number if it's less than 100
  }

  let roundedCount;
  if (totalCount < 10000) {
    // Round down to the nearest hundred
    roundedCount = Math.floor(totalCount / 100) * 100;
  } else {
    // Round down to the nearest thousand for larger values
    roundedCount = Math.floor(totalCount / 1000) * 1000;
  }
  return `${roundedCount}+`;
}
